<template>
  <layout-card
    title="차량 운행일지"
    :min-width="800">
    <template v-slot:body>
      <div
        class="d-flex flex-column">
        <div
          class="d-flex align-center">
          <v-btn
            @click="moveMonth(-1)"
            icon>
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <div class="date-title">
            <div class="date-title-year">
              {{ dateYear }}
            </div>
            <div class="date-title-month">
              {{ dateMonth }}
            </div>
          </div>
          <v-btn
            @click="moveMonth(1)"
            icon>
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn
            @click="onClickExport(true)"
            :disabled="selectItem.serial === ''"
            :loading="drivingJournal.loading"
            color="primary"
            class="mr-2"
            depressed>
            <v-icon left>
              mdi-file-document-outline
            </v-icon>
            내보내기
          </v-btn>
          <v-btn
            @click="onClickExport(false)"
            :loading="drivingJournal.loading"
            color="secondary"
            depressed>
            <v-icon left>
              mdi-file-document-multiple-outline
            </v-icon>
            모두 내보내기
          </v-btn>
        </div>
        <div>
          <heatmap-table
            @dblclick="$emit('dblclick')"
            :date="date"
            :items="items"
            :select.sync="selectItem"
            :height="$attrs.height">
          </heatmap-table>
        </div>
      </div>
    </template>
  </layout-card>
</template>

<script>
import util from '@/util';
import LayoutCard from '@/components/card/LayoutCard.vue';
import HeatmapTable from '@/components/output/HeatmapTable.vue';
import ExportJournal from '@/components/card/drivinghistory/ExportJournal';

export default {
  name: 'CardJournalTable',
  components: {
    LayoutCard,
    HeatmapTable,
  },
  props: {
    select: {
      type: Object,
      default: () => ({
        serial: '',
        start: '',
        end: '',
        count: 0,
      }),
    },
  },
  data: () => ({
    date: (() => {
      const now = new Date();
      now.setUTCHours(now.getUTCHours() + 9);
      now.setUTCDate(1);
      return now.toISOString().slice(0, 10);
    })(),
    items: {},
    drivingLog: {
      show: false,
      serial: null,
      start: null,
      end: null,
    },
    drivingJournal: {
      exporter: null,
      loading: false,
      idx: -1,
      identifier: null,
      start: null,
      end: null,
    },
  }),
  computed: {
    dateYear() {
      return this.date.slice(0, 4);
    },
    dateMonth() {
      return this.date.slice(5, 7);
    },
    selectItem: {
      get() {
        return this.select;
      },
      set(value) {
        if (this.select.serial !== value.serial
          || this.select.date !== value.date) {
          this.$emit('update:select', value);
        }
      },
    },
  },
  watch: {
    date: {
      immediate: true,
      handler() {
        this.getDrivingJournal();
      },
    },
  },
  sockets: {
    'term.journal.count.get': function _(data) {
      if (data.result === 'success') {
        this.items = data.items;
      } else {
        this.$store.dispatch('alert/setAlert', {
          color: 'error',
          message: data.message,
        });
      }
    },
    'term.location.driving.log.get': function _(data) {
      if (data.result === 'success') {
        if (data.identifier === this.drivingJournal.identifier) {
          // 해당 장치의 운행 기록 처리
          this.drivingJournal.exporter.writeData({
            serial: data.serial,
            data: {
              ...data,
              name: this.items[data.serial].name,
            },
          });
        }
      } else {
        this.$store.dispatch('alert/setAlert', {
          color: 'error',
          message: data.message,
        });
      }

      if (data.identifier === this.drivingJournal.identifier) {
        this.getDrivingLog();
      }
    },
  },
  methods: {
    moveMonth(direction) {
      const date = new Date(this.date);
      date.setMonth(date.getMonth() + direction);
      this.date = date.toISOString().slice(0, 10);
    },
    getDrivingJournal() {
      const end = new Date(this.date);
      end.setMonth(end.getMonth() + 1);

      this.$socket.emit('term.journal.count.get', {
        start: this.date,
        end: end.toISOString().slice(0, 10),
      });
    },
    async onClickExport(single) {
      this.drivingJournal.loading = true;

      this.drivingJournal.exporter = new ExportJournal.ExportJournal();

      this.drivingJournal.exporter.on('init', () => {
        const start = util.time.getUtcDate(this.date);
        const end = util.time.getUtcDate(this.date);
        end.month(end.month() + 1);

        this.drivingJournal.idx = 0;
        this.drivingJournal.identifier = null;
        this.drivingJournal.start = start.format('YYYY-MM-DD HH:mm:ss');
        this.drivingJournal.end = end.format('YYYY-MM-DD HH:mm:ss');

        if (single) {
          const idx = Object.keys(this.items)
            .findIndex((serial) => (serial === this.selectItem.serial));
          this.getDrivingLog(idx, true);
        } else {
          this.getDrivingLog(0, false);
        }
      });
    },
    getDrivingLog(idx, single) {
      const idx2 = (idx != null) ? idx : this.drivingJournal.idx;

      const serial = Object.keys(this.items)[idx2];

      this.drivingJournal.idx = (single) ? -1 : (idx2 + 1);

      if (serial != null) {
        this.drivingJournal.identifier = util.format.getIdentifier();

        this.$socket.emit('term.location.driving.log.get', {
          serial,
          start: this.drivingJournal.start,
          end: this.drivingJournal.end,
          identifier: this.drivingJournal.identifier,
        });
      } else {
        this.drivingJournal.exporter.storeFile();
        this.drivingJournal.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.date-title {
  text-align: center;
  margin-left: .5rem;
  margin-right: .5rem;
  line-height: normal;

  &-year {
    color: #383838;
    font-size: .9rem;
    margin-bottom: .25rem;
  }

  &-month {
    color: #2c3e50;
    font-size: 2.5rem;
    font-weight: bold;
  }
}
</style>
