import moment from 'moment';

export default {
  getLocalDate(date) {
    return moment.utc(date).local();
  },
  getLocalDateString(date) {
    return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
  },
  getUtcDate(date) {
    return moment(date).utc();
  },
  getUtcDateString(date) {
    return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
  },
};
