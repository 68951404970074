<template>
  <layout-card
    title="모니터링 화면 선택"
    :width="260"
    :min-width="200"
    :height="200">
    <template v-slot:body>
      <v-radio-group
        v-model="idx"
        class="ma-0">
        <div
          class="pa-2 d-flex justify-center">
          <div
            class="pl-4 pr-4 d-flex flex-column align-center justify-space-between">
            <div class="mb-2">
              <v-radio
                class="ml-2"
                hide-details>
              </v-radio>
            </div>
            <div class="check-icon-1">
              <img
                alt="icon"
                :src="iconLiveLayout" />
            </div>
            <div class="mt-2 check-label">
              4
            </div>
          </div>
          <div>
            <v-divider vertical>
            </v-divider>
          </div>
          <div
            class="pl-4 pr-4 d-flex flex-column align-center justify-space-between">
            <div class="mb-2">
              <v-radio
                class="ml-2"
                hide-details>
              </v-radio>
            </div>
            <div class="check-icon-2">
              <img
                alt="icon"
                :src="iconLiveLayout" />
            </div>
            <div class="mt-2 check-label">
              6
            </div>
          </div>
          <div>
            <v-divider vertical>
            </v-divider>
          </div>
          <div
            class="pl-4 pr-4 d-flex flex-column align-center justify-space-between">
            <div class="mb-2">
              <v-radio
                class="ml-2"
                hide-details>
              </v-radio>
            </div>
            <div class="check-icon-3">
              <img
                alt="icon"
                :src="iconLiveLayout" />
            </div>
            <div class="mt-2 check-label">
              9
            </div>
          </div>
        </div>
      </v-radio-group>
    </template>
  </layout-card>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutCard from '@/components/card/LayoutCard.vue';
import IconLiveLayout from '@/assets/live_layout.svg';

export default {
  name: 'CardLiveLayout',
  components: {
    LayoutCard,
  },
  data: () => ({
    iconLiveLayout: IconLiveLayout,
    idx: 0,
  }),
  computed: {
    ...mapGetters({
      live: 'config/live',
    }),
  },
  watch: {
    idx: {
      handler(value) {
        this.$store.commit('config/live', { limit: this.idxToLimit(value) });
      },
    },
    live: {
      immediate: true,
      handler(value) {
        this.idx = this.limitToIdx(value.limit);
      },
    },
  },
  methods: {
    idxToLimit(idx) {
      let limit;
      switch (idx) {
        case 0: limit = 4; break;
        case 1: limit = 6; break;
        case 2: limit = 9; break;
        default: limit = 9; break;
      }
      return limit;
    },
    limitToIdx(limit) {
      let idx;
      switch (limit) {
        case 4: idx = 0; break;
        case 6: idx = 1; break;
        case 9: idx = 2; break;
        default: idx = 2; break;
      }
      return idx;
    },
  },
};
</script>

<style lang="scss" scoped>
.check-icon {
  margin-right: 8px;

  &-1 {
    overflow: hidden;
    width: 32px;
    height: 32px;

    img {
      clip: rect(0, 0, 32px, 32px);
      width: 47px;
    }
  }

  &-2 {
    overflow: hidden;
    width: 48px;
    height: 32px;

    img {
      clip: rect(0, 0, 32px, 32px);
      width: 47px;
    }
  }

  &-3 {
    overflow: hidden;

    img {
      clip: rect(0, 0, 32px, 32px);
      width: 48px;
    }
  }
}

.check-label {
  color: #A3A3A3;
  font-weight: bold;
  font-size: 1rem;
}
</style>
