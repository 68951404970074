<template>
  <layout-dialog
    title="이벤트 목록"
    :show.sync="showFlag"
    :max-width="800">
    <template v-slot:body>
      <div>
        <div>
          <input-date-range
            v-model="eventList.dateRange"
            @click:search="getEventList">
          </input-date-range>
        </div>
        <v-progress-linear
          :indeterminate="eventList.loading"
          color="#373737">
        </v-progress-linear>
        <v-virtual-scroll
          class="event-list"
          :bench="5"
          :items="eventList.items"
          :height="listHeight"
          item-height="80">
          <template v-slot:default="{ index, item }">
            <template>
              <div
                :key="`item_${index}`">
                <v-list-item>
                  <v-list-item-icon>
                    <v-tooltip
                      :disabled="!hasSnapshot(item)"
                      right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          class="snapshot-container"
                          height="54"
                          width="96"
                          :src="`data:image/jpg;base64,
                          ${item.snapshot != null ? item.snapshot[item.channel + 1] : ''}`"
                          v-bind="attrs"
                          v-on="on">
                        </v-img>
                      </template>
                      <div class="d-flex">
                        <v-img
                          class="snapshot-container"
                          height="194"
                          width="345"
                          :src="`data:image/jpg;base64,
                          ${item.snapshot != null ? item.snapshot[item.channel + 1] : ''}`">
                        </v-img>
                      </div>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.address }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="event-msg">
                        {{ getEventString(item) }}
                      </span> | {{ toLocalDate(item.timestamp) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div
                      class="d-flex">
                      <div
                        style="width: 80px;"
                        class="ch-select">
                        <v-select
                          v-model="item.channel"
                          :disabled="!hasSnapshot(item)"
                          dense
                          hide-details
                          color="#929292"
                          item-text="text"
                          item-value="value"
                          :items="getChannelItems(item)">
                        </v-select>
                      </div>
                      <v-btn
                        @click="startPlayback({
                          serial,
                          fileName: item.file,
                          type: getChannel(item.channel),
                        })"
                        :disabled="!isPlayable(item)"
                        class="ml-4"
                        color="#929292"
                        outlined
                        small>
                        <v-icon
                          small>
                          mdi-play
                        </v-icon>
                      </v-btn>
                      <v-btn
                        @click="startDownload({
                          serial,
                          fileName: item.file,
                          mode: 0,
                          type: getChannel(item.channel),
                        })"
                        :disabled="!isPlayable(item)"
                        class="ml-4"
                        color="#929292"
                        outlined
                        small>
                        <v-icon
                          small>
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="index < eventList.items.length - 1">
                </v-divider>
              </div>
            </template>
          </template>
        </v-virtual-scroll>
      </div>
      <dlg-record-player
        v-if="playback.show"
        :show.sync="playback.show"
        :serial="serial"
        :file-name="playback.fileName"
        :mode="0"
        :type="playback.type">
      </dlg-record-player>
    </template>
  </layout-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as time from '@kim5257/js-time';
import util from '@/util';
import LayoutDialog from '@/components/dialog/LayoutDialog.vue';
import InputDateRange from '@/components/input/InputDateRange.vue';
import DlgRecordPlayer from '@/components/dialog/DlgRecordPlayer.vue';

export default {
  name: 'DlgEventList',
  components: {
    LayoutDialog,
    InputDateRange,
    DlgRecordPlayer,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    serial: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    eventList: {
      items: [],
      loading: false,
      dateRange: [],
    },
    playback: {
      show: false,
      fileName: '',
      type: 0,
    },
    geocoder: new window.kakao.maps.services.Geocoder(),
  }),
  computed: {
    ...mapGetters({
      displaySize: 'display/size',
    }),
    listHeight() {
      return this.displaySize.height - 265;
    },
    showFlag: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  sockets: {
    'term.device.event.list.get': function _(data) {
      // if (data.serial != null && data.serial === this.serial) {
      // eslint-disable-next-line no-lone-blocks
      {
        if (data.result === 'success') {
          this.eventList.items = data.items.map((item) => ({
            ...item,
            channel: 0,
            address: (item.address != null) ? item.address : '-',
          }));

          // this.doRequestAddress();
        } else {
          this.$store.dispatch('alert/setAlert', {
            message: data.message,
            color: 'error',
          });
        }

        this.eventList.loading = false;
      }
    },
    'term.device.event.list.get.address': function _(data) {
      if (data.result === 'success') {
        data.items.forEach((item) => {
          const idx = this.eventList.items.findIndex((event) => (item.no === event.no));
          this.eventList.items.splice(idx, 1, {
            ...this.eventList.items[idx],
            address: item.address,
          });
        });
      }
    },
  },
  mounted() {
    this.getEventList();
  },
  methods: {
    ...mapActions({
      startDownload: 'download/startDownload',
    }),
    getEventList() {
      this.eventList.loading = true;

      this.eventList.items = [];

      const start = this.eventList.dateRange[0];
      const end = (this.eventList.dateRange.length < 2) ? start : this.eventList.dateRange[1];

      this.$socket.emit('term.device.event.list.get', {
        serial: this.serial,
        start,
        end,
        snapshot: true,
      });
    },
    getEventString(item) {
      let str = '';

      switch (item.type) {
        case 'park':
          str = item.state === 'on' ? '주차 시작' : '주차 종료';
          break;
        case 'impact': str = '충격'; break;
        case 'motion': str = '움직임 감지'; break;
        case 'cutoff': str = '전원 차단'; break;
        default: str = '-';
      }

      return str;
    },
    toLocalDate(timestamp) {
      return (timestamp != null) ? time.localTimeString(timestamp) : '-';
    },
    hasSnapshot(item) {
      return (item.snapshot != null);
    },
    isPlayable(item) {
      return (item.file != null);
    },
    getChannelItems(item) {
      return Object.keys((item.snapshot != null) ? item.snapshot : {}).map((value) => ({
        text: `${value} 채널`,
        value: parseInt(value, 10) - 1,
      }));
    },
    getChannel(value) {
      let channel = 0;

      switch (value) {
        case 0: channel = 3; break;
        case 1: channel = 5; break;
        case 2: channel = 6; break;
        case 3: channel = 4; break;
        default: break;
      }

      return channel;
    },
    startPlayback({ fileName, type }) {
      this.playback.fileName = fileName;
      this.playback.type = type;
      this.playback.show = true;
    },
    searchDetailAddrFromCoords(position, cb) {
      this.geocoder.coord2Address(position.getLng(), position.getLat(), cb);
    },
    doRequestAddress() {
      this.$on('location.req', (idx) => {
        const item = { ...this.eventList.items[idx] };

        if (item.address === '-' && item.gps != null && item.gps.latitude !== 0) {
          const position = util.location.gpsToPosition(item.gps);

          this.searchDetailAddrFromCoords(position, (result, status) => {
            if (status === window.kakao.maps.services.Status.OK) {
              item.address = (result[0].road_address != null)
                ? result[0].road_address.address_name
                : result[0].address.address_name;
            } else {
              item.address = '-';
            }

            this.eventList.items.splice(idx, 1, item);

            if ((idx + 1) < this.eventList.items.length) {
              this.$emit('location.req', idx + 1);
            } else {
              this.$off('location.req');
            }
          });
        } else if ((idx + 1) < this.eventList.items.length) {
          this.$emit('location.req', idx + 1);
        } else {
          this.$off('location.req');
        }
      });

      this.$emit('location.req', 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.event-list {
  background-color: #E5E5E5;
  overflow-y: scroll;
}

.snapshot-container {
  background-color: #585858;
}

.v-tooltip__content {
  opacity: 1 !important;
}

.v-list-item {
  .v-list-item__content {
    .v-list-item__title {
      font-size: 1.2rem;
      margin-bottom: .5rem;
    }

    .v-list-item__subtitle {
      font-size: 1rem;
      color: #888888;

      .event-msg {
        font-weight: bold;
        color: #ff5252;
      }
    }
  }
}
</style>
