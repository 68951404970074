<template>
  <layout-dialog
    title="블랙박스 일괄 등록"
    :show.sync="showFlag"
    :max-width="600">
    <template v-slot:body>
      <v-card
        flat>
        <v-card-text>
          <drop-zone
            :loading="loading.loadFile"
            @change="onChange">
          </drop-zone>
          <div>
            등록 장치 수: {{ deviceList.items.length }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn
            @click="$emit('update:show', false);"
            :disabled="loading.addDevice"
            color="secondary"
            depressed>
            취소
          </v-btn>
          <v-btn
            @click="onAddDevice"
            :loading="loading.addDevice"
            color="primary"
            depressed>
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </layout-dialog>
</template>

<script>
import XLSX from 'xlsx';
import { mapActions } from 'vuex';
import LayoutDialog from '@/components/dialog/LayoutDialog.vue';
import DropZone from '@/components/input/DropZone.vue';

export default {
  name: 'DlgBatchAddDevice',
  components: {
    LayoutDialog,
    DropZone,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: {
      loadFile: false,
      addDevice: false,
    },
    deviceList: {
      items: [],
    },
  }),
  computed: {
    showFlag: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  sockets: {
    'term.device.add.batch': function _(data) {
      if (data.result === 'success') {
        this.getDevices();

        this.$store.dispatch('alert/setAlert', {
          message: '등록 완료',
          color: 'info',
        });

        this.showFlag = false;
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        });
      }
    },
  },
  methods: {
    ...mapActions({
      getDevices: 'devices/getDevices',
    }),
    onChange(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        let ret = true;

        this.step = 1;

        const binary = event.target.result;
        const wb = XLSX.read(binary, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        // 데이터의 각 항목이 인식되는지 확인
        const hdr = {
          serial: { idx: -1, required: true, allow: ['시리얼'] },
          nickname: { idx: -1, required: true, allow: ['블랙박스 이름'] },
        };
        const hdrEntries = Object.entries(hdr);

        data[0].forEach((item, idx) => {
          // 헤더 찾기
          for (let hdrIdx = 0; hdrIdx < hdrEntries.length; hdrIdx += 1) {
            const [key, info] = hdrEntries[hdrIdx];
            if (info.allow.findIndex((value) => value === item.trim()) >= 0) {
              hdr[key].idx = idx;
              break;
            }
          }
        });

        for (let hdrIdx = 0; hdrIdx < hdrEntries.length; hdrIdx += 1) {
          const [, info] = hdrEntries[hdrIdx];
          if (info.idx < 0) {
            ret = false;
            break;
          }
        }

        if (ret) {
          this.deviceList.items = [];

          for (let idx = 1; idx < data.length; idx += 1) {
            const row = data[idx];

            let valid = true;

            const newItem = {
              serial: row[hdr.serial.idx],
              nickname: row[hdr.nickname.idx],
            };

            Object.entries(newItem).forEach(([key, value]) => {
              valid = valid && (!hdr[key].required || value != null);
            });

            if (valid) {
              this.deviceList.items.push(newItem);
            }
          }
        } else {
          console.error('error');
          // TODO: 에러 표시
        }
      };

      reader.readAsBinaryString(file);
    },
    onAddDevice() {
      this.$socket.emit('term.device.add.batch', { items: this.deviceList.items });
    },
  },
};
</script>

<style scoped>

</style>
