<template>
  <layout-dialog
    title="블랙박스 등록"
    :show.sync="showFlag"
    :max-width="1920">
    <template v-slot:body>
      <div
        class="d-flex">
        <v-spacer>
        </v-spacer>
        <v-btn
          @click="dialog.showAddDevice = true"
          color="primary"
          class="mr-2"
          depressed>
          <v-icon left>
            mdi-plus-box
          </v-icon>
          개별 등록
        </v-btn>
        <v-btn
          @click="dialog.showBatchDevice = true"
          color="primary"
          class="mr-2"
          depressed>
          <v-icon left>
            mdi-plus-box-multiple
          </v-icon>
          일괄등록
        </v-btn>
        <v-btn
          @click="dialog.showDeleteConfirm = true"
          color="error"
          class="mr-2"
          depressed>
          <v-icon left>
            mdi-close-box
          </v-icon>
          제거
        </v-btn>
        <v-btn
          color="secondary"
          depressed>
          <v-icon left>
            mdi-microsoft-excel
          </v-icon>
          내보내기
        </v-btn>
      </div>
      <div>
        <device-table2
          :items="devices"
          :selected-items.sync="selectedDevices"
          @update:name="onUpdateName">
        </device-table2>
      </div>
      <dlg-add-device
        v-if="dialog.showAddDevice"
        :show.sync="dialog.showAddDevice">
      </dlg-add-device>
      <dlg-batch-add-device
        v-if="dialog.showBatchDevice"
        :show.sync="dialog.showBatchDevice">
      </dlg-batch-add-device>
      <dlg-confirm
        @response="onClickRemoveDevice"
        :show.sync="dialog.showDeleteConfirm"
        title="삭제 확인"
        message="선택한 장치를 정말로 삭제하시겠습니까?">
      </dlg-confirm>
    </template>
  </layout-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LayoutDialog from '@/components/dialog/LayoutDialog.vue';
import DeviceTable2 from '@/components/output/DeviceTable2.vue';
import DlgAddDevice from '@/components/dialog/DlgAddDevice.vue';
import DlgBatchAddDevice from '@/components/dialog/DlgBatchDevice.vue';
import DlgConfirm from '@/components/dialog/DlgConfirm.vue';

export default {
  name: 'DlgAddDeviceTable',
  components: {
    LayoutDialog,
    DeviceTable2,
    DlgAddDevice,
    DlgBatchAddDevice,
    DlgConfirm,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    selectedDevices: [],
    dialog: {
      showAddDevice: false,
      showBatchDevice: false,
      showDeleteConfirm: false,
    },
  }),
  computed: {
    ...mapGetters({
      devices: 'devices/rawDevices',
    }),
    showFlag: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  sockets: {
    'term.device.remove.batch': function _(data) {
      if (data.result === 'success') {
        this.getDevices();
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        });
      }
    },
  },
  methods: {
    ...mapActions({
      getDevices: 'devices/getDevices',
    }),
    onClickRemoveDevice(response) {
      if (response) {
        this.$socket.emit('term.device.remove.batch', {
          items: this.selectedDevices.map((item) => ({ serial: item.serial })),
        });
      }
    },
    onUpdateName(item) {
      this.$store.dispatch('devices/updateDevice', item);
    },
  },
};
</script>

<style scoped>

</style>
