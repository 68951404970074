<template>
  <div
    ref="table"
    class="table-container"
    :style="{
      overflowY: 'auto',
      height: `${height}px`
    }">
    <v-timeline
      dense
      class="text-left">
      <template
        v-for="(item, idx) in items">
        <v-timeline-item
          v-if="idx === 0 || (getDate(items[idx - 1].end) !== getDate(item.start))"
          hide-dot
          :key="`date_start_sep_${idx}`">
          <v-chip
            color="info"
            small>
            {{ getDate(item.start) }}
          </v-chip>
        </v-timeline-item>
        <v-timeline-item
          @click="onClickItem(item)"
          :key="`item_start_${idx}`"
          color="red lighten-2">
          <div>
            <div>출발 {{ getTime(item.start) }}</div>
            <div>{{ item.start_address }}</div>
          </div>
        </v-timeline-item>
        <v-timeline-item
          v-if="item.end != null && getDate(item.end) !== getDate(item.start)"
          hide-dot
          :key="`date_end_sep_${idx}`">
          <v-chip
            color="info"
            small>
            {{ getDate(item.end) }}
          </v-chip>
        </v-timeline-item>
        <v-timeline-item
          :key="`info_${idx}`"
          hide-dot>
          <div
            class="driving-distance-info mr-4">
            <div
              v-if="item.end != null"
              class="d-flex">
              <div class="ma-auto">
                {{ subTimeString(item.start, item.end) }} {{ toDistString(item.distance) }} 이동
              </div>
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  @click="$emit('click:path', item)"
                  color="primary"
                  outlined>
                  경로보기
                </v-btn>
              </div>
            </div>
            <div v-else>
              운행 중
            </div>
          </div>
        </v-timeline-item>
        <v-timeline-item
          :key="`item_end_${idx}`"
          :color="(item.end != null) ? 'blue lighten-2' : 'grey lighten-2'">
          <div
            v-if="item.end != null">
            <div>도착 {{ getTime(item.end) }}</div>
            <div>{{ item.end_address }}</div>
          </div>
        </v-timeline-item>
        <v-timeline-item
          hide-dot
          :key="`item_divider_${idx}`">
          <div
            class="mt-2 mb-2 mr-4">
            <v-divider></v-divider>
          </div>
        </v-timeline-item>
      </template>
    </v-timeline>
  </div>
</template>

<script>
import util from '@/util';

export default {
  name: 'DrivingLogTable',
  props: {
    items: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      default: 240,
    },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          const { table } = this.$refs;
          table.scrollTop = table.scrollHeight - table.clientHeight;
        });
      },
    },
  },
  methods: {
    toDistString(distance) {
      const unit = (distance >= 1000) ? 'km' : 'm';
      const value = (distance >= 1000) ? (Math.floor(distance / 100) / 10) : distance;
      return `${value}${unit}`;
    },
    subTimeString(start, end) {
      let sec = ((new Date(end)) - (new Date(start))) / 1000;
      const hour = Math.floor(sec / 60 / 60);
      sec -= (hour * 60 * 60);
      const min = Math.floor(sec / 60);
      sec -= (min * 60);

      return `${(hour > 0) ? hour : '00'}:${(min > 9) ? '' : '0'}${min}:${(sec > 9) ? '' : '0'}${sec}`;
    },
    getTime(date) {
      return util.time.getLocalDate(date).format('HH:mm');
    },
    getDate(date) {
      return util.time.getLocalDate(date).format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  min-width: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.driving-distance-info {
  color: #64b1de;
  font-weight: bold;
  font-size: 1rem;
}
</style>
