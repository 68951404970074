<template>
  <layout-card
    :width="width"
    :height="height"
    :title="name">
    <template v-slot:title>
      {{ name }}
      <v-spacer>
      </v-spacer>
      <div class="time-string">
        {{ Math.ceil(currentTime) }} / {{ Math.ceil(totalDuration) }}s
      </div>
    </template>
    <template v-slot:body>
      <v-card
        flat
        rounded>
        <live-player
          v-if="!hidePlayer"
          @play="checkOnline"
          @stop="stopLive(true)"
          @stopped="stopLive(true)"
          @timeupdate="onTimeupdate"
          :width="playerWidth"
          :height="playerHeight"
          :loading.sync="loading"
          :src.sync="src">
        </live-player>
        <dlg-data-confirm
          @response="onConfirm"
          v-if="confirmShow"
          :show.sync="confirmShow">
        </dlg-data-confirm>
      </v-card>
    </template>
  </layout-card>
</template>

<script>
import MixinCheckOnline from '@/components/card/MixinCheckOnline';
import LayoutCard from '@/components/card/LayoutCard.vue';
import LivePlayer from '@/components/output/LivePlayer.vue';
import DlgDataConfirm from '@/components/dialog/DlgDataConfirm.vue';

export default {
  name: 'CardLive',
  mixins: [MixinCheckOnline],
  components: {
    LayoutCard,
    LivePlayer,
    DlgDataConfirm,
  },
  props: {
    name: {
      type: String,
      default: '-',
    },
    serial: {
      type: String,
      default: '-',
    },
    trigger: {
      type: Object,
      default: null,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 210,
    },
  },
  data: () => ({
    type: 3,
    src: '',
    loading: false,
    stopFlag: false,
    currentTime: 0,
    totalDuration: 0,
    hidePlayer: false,
  }),
  computed: {
    playerWidth() {
      return ((this.width - 29) > 264) ? this.width - 29 : 264;
    },
    playerHeight() {
      return this.height - 59;
    },
  },
  sockets: {
    'live.play': function _(data) {
      const serial = (data.result === 'success') ? (data.serial) : (data.target.slice(3));

      if (serial === this.serial) {
        if (data.result === 'success') {
          let url = `https://bbnms.cns-link.net:8080/hls/${data.url}.m3u8`;

          if (data.uri != null) {
            url = data.uri.replace('rtmp', 'https')
              .replace('49.247.0.154', 'bbnms2.cns-link.net')
              .replace('live', 'hls')
              .replace(':1935', ':8080')
              .concat('.m3u8');
          }
          this.src = url;
        } else {
          let { message } = data;

          if (data.name === 'ETIMEOUT') {
            message = '블랙박스 응답이 없습니다';
          }

          this.$store.dispatch('alert/setAlert', {
            message: `[${this.serial}] ${message}`,
            color: 'error',
          });
        }

        this.loading = false;
      }
    },
    'live.stop': function _(data) {
      const serial = (data.result === 'success') ? (data.serial) : (data.target.slice(3));

      if (serial === this.serial) {
        if (data.result === 'success') {
          if (!this.stopFlag) {
            this.playLive();
          }
        } else {
          let { message } = data;

          if (message === 'Does not respond') {
            message = '블랙박스로부터 응답이 없습니다.';
          }

          this.$store.dispatch('alert/setAlert', {
            color: 'error',
            message: `[${this.serial}] ${message}`,
          });
        }
      }
    },
  },
  mounted() {
    if (this.trigger != null) {
      this.trigger.$on('live.play', () => {
        if (!this.loading && this.src === '') {
          this.startLive();
        }
      });

      this.trigger.$on('live.stop', () => {
        this.stopLive();
      });

      this.trigger.$on('reset', () => {
        this.hidePlayer = true;
        this.$nextTick(() => {
          this.loading = false;
          this.hidePlayer = false;
        });
      });
    }
  },
  methods: {
    startLive() {
      this.loading = true;
      this.playLive();
    },
    playLive() {
      this.$socket.emit('live.play', {
        serial: this.serial,
        type: this.type,
        protocol: 2,
      });
    },
    stopLive(flag) {
      this.stopFlag = flag;
      this.$socket.emit('live.stop', {
        serial: this.serial,
        protocol: 2,
      });

      this.src = '';
    },
    onTimeupdate({ currentTime, totalDuration }) {
      this.currentTime = currentTime;
      this.totalDuration = totalDuration;
    },
    onConfirm(result) {
      if (result) {
        this.startLive();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.time-string {
  font-size: 1rem;
  color: #7E7E7E;
}

.video-container {
  width: 320px;
  height: 180px;
  border-radius: .5rem;
  background-color: #C8C8C8;
}
</style>
