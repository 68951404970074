<template>
  <div>
    <div
      ref="container"
      :style="{ height: `${height}px` }">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import imgStartMarker from '@/assets/start_marker.png';
import imgEndMarker from '@/assets/end_marker.png';

export default {
  name: 'DrivingLogMap',
  props: {
    height: {
      type: Number,
      default: 600,
    },
    pathItems: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    map: null,
    center: null,
    icons: {
      start: {
        icon: imgStartMarker,
        info: {
          width: 42,
          height: 42,
          x: 21,
          y: 42,
        },
      },
      end: {
        icon: imgEndMarker,
        info: {
          width: 42,
          height: 42,
          x: 21,
          y: 42,
        },
      },
    },
    path: {
      startMarker: null,
      endMarker: null,
      line: null,
    },
  }),
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
      selectedDevice: 'devices/select',
      locationTasks: 'location/tasks',
      locationFlag: 'location/flag',
      coordinates: 'location/coordinates',
      level: 'location/level',
      locationLog: 'location/locationLog',
    }),
  },
  watch: {
    pathItems: {
      immediate: false,
      handler() {
        if (this.path.line != null) {
          this.path.line.setMap(null);
          this.path.line = null;
        }

        if (this.path.startMarker != null) {
          this.path.startMarker.setMap(null);
          this.path.startMarker = null;
        }

        if (this.path.endMarker != null) {
          this.path.endMarker.setMap(null);
          this.path.endMarker = null;
        }

        const pathInfo = this.makePath({
          items: this.pathItems,
        });

        this.path.line = pathInfo.polyline;
        this.path.line.setMap(this.map);

        this.path.startMarker = this.makeMarker({
          location: this.pathItems[0].coordinates,
          image: this.icons.start,
          zIndex: 2,
        });
        this.path.endMarker = this.makeMarker({
          location: this.pathItems[this.pathItems.length - 1].coordinates,
          image: this.icons.end,
          zIndex: 3,
        });

        this.path.startMarker.setMap(this.map);
        this.path.endMarker.setMap(this.map);

        const bounds = new window.kakao.maps.LatLngBounds();
        bounds.extend(new window.kakao.maps.LatLng(
          pathInfo.boundPoint[0].lat,
          pathInfo.boundPoint[0].lng,
        ));
        bounds.extend(new window.kakao.maps.LatLng(
          pathInfo.boundPoint[1].lat,
          pathInfo.boundPoint[1].lng,
        ));
        this.map.setBounds(bounds);
      },
    },
  },
  mounted() {
    this.initMap();
  },
  beforeDestroy() {
    this.releaseMap();
  },
  methods: {
    initMap() {
      if (this.map == null) {
        this.center = new window.kakao.maps.LatLng(this.coordinates.lat, this.coordinates.lng);

        this.map = new window.kakao.maps.Map(this.$refs.container, {
          center: this.center,
          level: this.level,
        });

        this.map.addControl(
          new window.kakao.maps.ZoomControl(),
          window.kakao.maps.ControlPosition.RIGHT,
        );
      }

      this.$nextTick(() => {
        this.map.relayout();
        this.map.setCenter(this.center);
      });
    },
    releaseMap() {
    },
    makeMarker({ location, image, zIndex }) {
      return new window.kakao.maps.Marker({
        position: new window.kakao.maps.LatLng(
          location.lat,
          location.lng,
        ),
        image: new window.kakao.maps.MarkerImage(
          image.icon,
          new window.kakao.maps.Size(image.info.width, image.info.height),
          { offset: new window.kakao.maps.Point(image.info.x, image.info.y) },
        ),
        zIndex,
      });
    },
    makePath({ items }) {
      const boundPoint = [
        { lat: 999, lng: 999 },
        { lat: -999, lng: -999 },
      ];

      const path = [];
      items.forEach((item) => {
        path.push(new window.kakao.maps.LatLng(item.coordinates.lat, item.coordinates.lng));

        // 위도/경도 최대/최소 값 구하기
        boundPoint[0].lat = Math.min(item.coordinates.lat, boundPoint[0].lat);
        boundPoint[0].lng = Math.min(item.coordinates.lng, boundPoint[0].lng);
        boundPoint[1].lat = Math.max(item.coordinates.lat, boundPoint[1].lat);
        boundPoint[1].lng = Math.max(item.coordinates.lng, boundPoint[1].lng);
      });

      return {
        polyline: new window.kakao.maps.Polyline({
          path,
          strokeWeight: 5,
          strokeColor: '#c71717',
          strokeOpacity: 0.7,
          strokeStyle: 'solid',
          endArrow: true,
          zIndex: 1,
        }),
        boundPoint,
      };
    },
  },
};
</script>

<style scoped>

</style>
