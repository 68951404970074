<template>
  <v-dialog
    @input="(open) => { if (!open) { $emit('click:search'); }}"
    ref="dialog"
    v-model="show"
    :return-value.sync="date"
    persistent
    width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        @click:append-outer="$emit('click:search')"
        :value="strDate"
        prepend-icon="mdi-calendar"
        append-outer-icon="mdi-magnify"
        readonly
        v-on="on"
        v-bind="attrs">
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      range
      scrollable>
      <v-spacer>
      </v-spacer>
      <v-btn
        text
        @click="show = false">
        취소
      </v-btn>
      <v-btn
        color="primary"
        @click="$refs.dialog.save(date)">
        확인
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import * as time from '@kim5257/js-time';

export default {
  name: 'InputDateRange',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    show: false,
    tmp: [],
  }),
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    strDate() {
      return (this.date.length > 1)
        ? `${this.date[0]} ~ ${this.date[1]}`
        : this.date[0];
    },
  },
  watch: {
    date: {
      immediate: true,
      handler(value) {
        if (value.length === 0) {
          const now = time.localDate(new Date().toISOString());
          this.date = [now, now];
        } else if (value.length > 1 && (value[0] > value[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      },
    },
  },
};
</script>

<style scoped>

</style>
