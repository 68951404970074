<template>
  <layout-card>
    <template v-slot:title>
      <span>
        지도보기
      </span>
      <v-spacer>
      </v-spacer>
      <v-tooltip
        left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="resetLocation"
            width="32"
            height="32"
            icon
            v-bind="attrs"
            v-on="on">
            <v-icon>
              mdi-overscan
            </v-icon>
          </v-btn>
        </template>
        <span>지도 보기 위치 초기화</span>
      </v-tooltip>
    </template>
    <template v-slot:body>
      <location-map
        :height="$attrs.height + 53"
        use-tracking>
      </location-map>
    </template>
  </layout-card>
</template>

<script>
import LayoutCard from '@/components/card/LayoutCard.vue';
import LocationMap from '@/components/output/LocationMap.vue';
import { mapActions } from 'vuex';

export default {
  name: 'CardLocationMap',
  components: {
    LayoutCard,
    LocationMap,
  },
  methods: {
    ...mapActions({
      resetLocation: 'location/reset',
    }),
  },
};
</script>

<style scoped>
</style>
