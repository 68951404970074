import location from './location';
import time from './time';
import format from './format';
import vue from './vue';

export default {
  location,
  time,
  format,
  vue,
};
