<template>
  <layout-dialog
    :title="`운행이력 (${name})`"
    :show.sync="showFlag"
    :max-width="1920">
    <template v-slot:body>
      <div
        class="d-flex">
        <div>
          <driving-log-table
            @click:path="onClickShowPath"
            :height="displaySize.height - 178"
            :items="items">
          </driving-log-table>
        </div>
        <div
          class="flex-grow-1 ml-4">
          <driving-log-map
            :height="displaySize.height - 178"
            :path-items="drivingLog.items">
          </driving-log-map>
        </div>
      </div>
    </template>
  </layout-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import util from '@/util';
import LayoutDialog from '@/components/dialog/LayoutDialog.vue';
import DrivingLogTable from '@/components/output/DrivingLogTable.vue';
import DrivingLogMap from '@/components/output/DrivingLogMap.vue';

export default {
  name: 'DlgDrivingLog',
  components: {
    LayoutDialog,
    DrivingLogTable,
    DrivingLogMap,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    serial: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    start: {
      type: String,
      default: (() => {
        const date = new Date();
        date.setDate(date.getDate() - 14);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        const isoDate = date.toISOString();
        return `${isoDate.slice(0, 10)} ${isoDate.slice(11, 19)}`;
      })(),
    },
    end: {
      type: String,
      default: (() => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        const isoDate = date.toISOString();
        return `${isoDate.slice(0, 10)} ${isoDate.slice(11, 19)}`;
      })(),
    },
    initItems: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    items: [],
    drivingLog: {
      identifier: '0',
      selectedItem: null,
      items: [],
    },
  }),
  computed: {
    ...mapGetters({
      displaySize: 'display/size',
    }),
    showFlag: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  sockets: {
    'term.location.driving.log.get': function _(data) {
      if (data.result === 'success') {
        this.items = data.items;
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: `[${this.serial}] ${data.message}`,
          color: 'error',
        });
      }
    },
    'term.location.log.get': function _(data) {
      if (data.result === 'success') {
        if (data.identifier === this.drivingLog.identifier) {
          const lastItem = ((this.drivingLog.selectedItem.end_gps != null)
            && (this.drivingLog.selectedItem.end_gps.lat > 0))
            ? [{
              no: 0,
              coordinates: util.location.gpsToCoordinates(this.drivingLog.selectedItem.end_gps),
            }] : [];

          this.drivingLog.items = [
            ...data.items,
            ...lastItem,
          ];
        }
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: `[${this.serial}] ${data.message}`,
          color: 'error',
        });
      }
    },
  },
  mounted() {
    if (this.initItems != null) {
      this.items = [...this.initItems];
    } else {
      this.getDrivingLog();
    }
  },
  methods: {
    getDrivingLog() {
      this.$socket.emit('term.location.driving.log.get', {
        serial: this.serial,
        identifier: '0',
        start: this.start,
        end: this.end,
      });
    },
    onClickShowPath(item) {
      this.drivingLog.identifier = this.makeIdentifier();
      this.drivingLog.selectedItem = item;

      this.$socket.emit('term.location.log.get', {
        serial: this.serial,
        start: item.start.slice(0, 19),
        end: item.end.slice(0, 19),
        identifier: this.drivingLog.identifier,
      });
    },
    makeIdentifier() {
      return new Date().getTime().toString();
    },
  },
};
</script>

<style scoped>

</style>
