<template>
  <div
    :style="{ height: `${containerHeight}px` }">
    <v-card
      flat
      tile
      height="100%">
      <v-card-title>
        Welcome ~
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-card-text
        class="d-flex justify-center align-center"
        style="height: 100%;">
        <div class="mr-4">
          <v-card
            class="login-card"
            tile
            max-width="320">
            <v-card-title class="pa-1 ma-0 text-center">
              <v-img
                :src="loginLogo">
              </v-img>
            </v-card-title>
            <router-view/>
          </v-card>
        </div>
        <div
          class="pa-4 d-none d-md-block d-sm-none">
          <v-img
            contain
            :src="mainImg">
          </v-img>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import MainImg from '@/assets/main.png';
import LoginLogo from '@/assets/login_logo.png';

export default {
  name: 'Login',
  data: () => ({
    mainImg: MainImg,
    loginLogo: LoginLogo,
  }),
  computed: {
    containerHeight() {
      return window.innerHeight
        - (this.$vuetify.application.top + this.$vuetify.application.footer) - 24;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  height: 100%;

  .v-card__title {
    color: #0A6FBD;
    font-size: 12pt;
    font-weight: 700;
    padding-top: 3pt;
    padding-bottom: 3pt;
  }

  .v-divider {
    border: #c5c5c5 solid 1.5px;
  }

  .btn-more {
    border-radius: 4px;
  }
}

.login-card {
  border: #c4c4c4 solid 2px;
  box-shadow:
    3px 3px 5px -2px rgba(0, 0, 0, 0.2),
    3px 3px 5px 0px rgba(0, 0, 0, 0.14),
    3px 5px 5px 0px rgba(0, 0, 0, 0.12) !important;

  &-btn-container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
</style>
