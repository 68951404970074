<template>
  <layout-dialog
    :show.sync="showFlag"
    :max-width="800">
    <template v-slot:title>
      <div class="time-string">
        {{ Math.ceil(currentTime) }} / {{ Math.ceil(totalDuration) }}s
      </div>
      <v-spacer>
      </v-spacer>
      {{ fileName }}
      <v-spacer>
      </v-spacer>
      <v-btn
        @click="showFlag = false"
        icon>
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:body>
      <div>
        <live-player
          @play="startPlayback"
          @stop="stopPlayback"
          @stopped="stopPlayback"
          @timeupdate="onTimeupdate"
          :loading.sync="loading"
          :src.sync="src"
          :width="752"
          :height="423">
        </live-player>
      </div>
    </template>
  </layout-dialog>
</template>

<script>
import LayoutDialog from '@/components/dialog/LayoutDialog.vue';
import LivePlayer from '@/components/output/LivePlayer.vue';

export default {
  name: 'DlgRecordPlayer',
  components: {
    LayoutDialog,
    LivePlayer,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    serial: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    mode: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    src: '',
    currentTime: 0,
    totalDuration: 0,
  }),
  computed: {
    showFlag: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  sockets: {
    'playback.play': function _(data) {
      const serial = (data.result === 'success') ? (data.serial) : (data.target.slice(3));

      if (serial === this.serial) {
        if (data.result === 'success') {
          let url = `https://bbnms.cns-link.net:8080/hls/${data.url}.m3u8`;

          if (data.uri != null) {
            url = data.uri.replace('rtmp', 'https')
              .replace('49.247.0.154', 'bbnms2.cns-link.net')
              .replace('live', 'hls')
              .replace(':1935', ':8080')
              .concat('.m3u8');
          }
          this.src = url;
        } else {
          let { message } = data;

          if (data.name === 'ETIMEOUT') {
            message = '블랙박스 응답이 없습니다';
          }

          this.$store.dispatch('alert/setAlert', {
            message: `[${this.serial}] ${message}`,
            color: 'error',
          });
        }

        this.loading = false;
      }
    },
    'playback.stop': function _(data) {
      const serial = (data.result === 'success') ? (data.serial) : (data.target.slice(3));

      if (serial === this.serial) {
        if (data.result === 'success') {
          let { message } = data;

          if (message === 'Does not respond') {
            message = '블랙박스로부터 응답이 없습니다.';
          }

          this.$store.dispatch('alert/setAlert', {
            color: 'error',
            message: `[${this.serial}] ${message}`,
          });
        }
      }
    },
  },
  mounted() {
    this.startPlayback();
  },
  beforeDestroy() {
    this.stopPlayback();
  },
  methods: {
    startPlayback() {
      this.loading = true;

      this.$socket.emit('playback.play', {
        serial: this.serial,
        fileName: this.fileName,
        protocol: 2,
        mode: this.mode,
        type: this.type,
      });
    },
    stopPlayback() {
      this.$socket.emit('playback.stop', {
        serial: this.serial,
        protocol: 2,
      });

      this.src = '';
    },
    onTimeupdate({ currentTime, totalDuration }) {
      this.currentTime = currentTime;
      this.totalDuration = totalDuration;
    },
  },
};
</script>

<style lang="scss" scoped>
.time-string {
  font-size: 1rem;
  color: #7E7E7E;
}
</style>
