<template>
  <v-card
    tile
    flat
    min-width="480">
    <v-card-title>
      <span>
        차량 블랙박스 목록 ({{ devices.length }} 대)
      </span>
      <v-spacer>
      </v-spacer>
      <div
        class="title-legend d-flex">
        <div>
          <v-icon>
            mdi-car
          </v-icon>
          <span>주차중</span>
        </div>
        <div
          class="ml-2">
          <v-icon
            color="#31B6EE">
            mdi-car
          </v-icon>
          <span>운행중</span>
        </div>
        <div
          class="ml-2">
          <v-icon
            size="18"
            color="#7AFE00">
            mdi-square
          </v-icon>
          <span>정상</span>
        </div>
        <div
          class="ml-2">
          <v-icon
            size="18"
            color="#7F7F7F">
            mdi-square
          </v-icon>
          <span>에러</span>
        </div>
      </div>
    </v-card-title>
    <v-divider
      class="title-divider">
    </v-divider>
    <v-card-text>
      <div
        class="list-container">
        <v-list-item
          class="list-header">
          <v-list-item-avatar>
            <span>상태</span>
          </v-list-item-avatar>
          <v-list-item-content
            class="flex-grow-1">
            <span>이름</span>
          </v-list-item-content>
          <v-list-item-action
            class="d-flex flex-row align-center">
            <div
              :style="{ marginLeft: '5px' }">
              GPS
            </div>
            <div
              :style="{ marginRight: '50px', marginLeft: '25px' }">
              Data
            </div>
          </v-list-item-action>
        </v-list-item>
        <v-virtual-scroll
          style="overflow-y: scroll;"
          :bench="5"
          :items="devices"
          :height="height"
          item-height="64">
          <template v-slot:default="{ index, item }">
            <v-list-item
              @dblclick="$emit('dblclick', item)"
              @click="selectDevice(index)"
              :input-value="select === index"
              :key="`item_${index}`">
              <v-list-item-avatar>
                <v-badge
                  :value="(item.readFlag != null) && (item.readFlag)"
                  color="warning"
                  overlap
                  dot
                  content="!">
                  <v-icon
                    :color=statusColor(item)>
                    {{ statusIcon(item) }}
                  </v-icon>
                </v-badge>
              </v-list-item-avatar>
              <v-list-item-content
                class="flex-grow-1">
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.serial }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action
                class="d-flex flex-row align-center">
                <div class="ml-2 mr-4">
                  <v-icon
                    :color="gpsStatus(item) ? '#7AFE00' : '#7F7F7F'"
                    small>
                    mdi-square
                  </v-icon>
                </div>
                <div class="data-usage-container d-flex ml-2 mr-4">
                  <v-progress-circular
                    color="primary"
                    :rotate="((100 - usageRatio(item)) * (360 / 100)) - 90"
                    :size="16"
                    :width="8"
                    :value="usageRatio(item)">
                  </v-progress-circular>
                  <span class="ml-auto">
                    {{ 100 - usageRatio(item) }}%
                  </span>
                </div>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </div>
    </v-card-text>
    <v-snackbar
      top
      :timeout="-1"
      :value="devices[select].readFlag"
      color="warning">
      <div
        class="d-flex align-center">
        <v-icon
          class="mr-4">
          mdi-alert-outline
        </v-icon>
        주행 범위를 벗어난 기록이 있습니다.
        <v-spacer></v-spacer>
        <v-btn
          @click="onClearOutFlag(devices[select])"
          icon>
          <v-icon>
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import devicelist from './devicelist';

export default {
  name: 'DeviceTable',
  mixins: [
    devicelist,
  ],
  data: () => ({
    headers: [
      { text: '상태', value: 'park' },
      { text: '시리얼', value: 'serial' },
      { text: '차량번호', value: 'name' },
      { text: 'GPS', value: 'gps' },
      { text: 'BB', value: 'status' },
    ],
  }),
  computed: {
    ...mapGetters({
      select: 'devices/select',
    }),
  },
  watch: {
    devices: {
      immediate: true,
      handler() {
        this.$emit('input', this.devices[this.select]);
      },
    },
    select: {
      immediate: true,
      handler() {
        this.$emit('input', this.devices[this.select]);
      },
    },
  },
  methods: {
    ...mapMutations({
      selectDevice: 'devices/select',
    }),
    statusIcon(item) {
      let icon = 'mdi-car-off';

      if (this.drivingStatus(item)) {
        icon = 'mdi-car';
      } else if (this.deviceStatus(item)) {
        icon = 'mdi-parking';
      }

      return icon;
    },
    statusColor(item) {
      return this.deviceStatus(item) ? '#31B6EE' : '#7F7F7F';
    },
    usageRatio(item) {
      const kbLimit = (item.limit > 0) ? (item.limit * 1024) : (-1);
      return Math.round((item.limit > 0) ? ((item.usage * 100) / kbLimit) : 100);
    },
    onClearOutFlag(item) {
      console.log('clear:', item);
      this.$socket.emit('term.device.fence.flag.clear', { serial: item.serial });
      this.$store.dispatch('devices/getDevices');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  color: #1D7CC1;
  font-weight: bold;
  padding: .5rem 1rem;

  .title-legend {
    font-size: 1rem;
    color: #585858;
  }
}

.title-divider {
  border-color: #B1B1B1;
  border-width: 1px;
}

.list-container {
  border: #E0E0E0 1px solid;
  padding: .14rem;
}

.list-header {
  min-height: 24px;
  font-weight: 800;
  background-color: #E0E0E0;
  font-size: 1rem;
  margin-bottom: .25rem;

  .v-avatar {
    color: #585858;
    margin-top: 0;
    margin-bottom: 0;
    height: 32px !important;
  }
  .v-list-item__content {
    color: #585858;
    padding-top: 0;
    padding-bottom: 0;
  }
  .v-list-item__action {
    color: #585858;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.v-list-item--active::before {
  opacity: 0 !important;
}

.v-list-item {
  border: transparent solid 2px;
}

.v-list-item--active {
  border: #2fb8ec solid 2px;
}

.data-usage-container {
  min-width: 52px;
}
</style>
