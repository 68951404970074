<template>
  <div
    class="d-flex">
    <div>
      <device-table
        v-model="device"
        :height="listHeight - 142">
      </device-table>
    </div>
    <div
      class="ml-4"
      style="overflow-y: auto; overflow-x: hidden;"
      :style="{ height: `${listHeight - 24}px` }">
      <v-row
        class="device-card-list"
        align="start"
        justify="start">
        <v-col>
          <card-device-info
            v-bind="device">
          </card-device-info>
        </v-col>
        <v-col>
          <card-drive-info
            v-bind="device"
            :driving-log="drivingLog">
          </card-drive-info>
        </v-col>
        <v-col>
          <card-run-status
            v-bind="device">
          </card-run-status>
        </v-col>
        <v-col>
          <card-location
            v-bind="device">
          </card-location>
        </v-col>
        <v-col>
          <card-live2
            v-bind="device">
          </card-live2>
        </v-col>
        <v-col>
          <card-data-usage
            v-bind="device">
          </card-data-usage>
        </v-col>
        <v-col>
          <card-drive-log
            v-bind="device"
            :driving-log="drivingLog">
          </card-drive-log>
        </v-col>
        <v-col>
          <card-event-record
            @show:more="dialog.eventVideo = true"
            v-bind="device">
          </card-event-record>
        </v-col>
        <v-col>
          <card-alarm
            @show:more="dialog.eventList = true"
            v-bind="device">
          </card-alarm>
        </v-col>
      </v-row>
    </div>
    <dlg-event-video
      v-if="dialog.eventVideo"
      :show.sync="dialog.eventVideo"
      v-bind="device">
    </dlg-event-video>
    <dlg-event-list
      v-if="dialog.eventList"
      :show.sync="dialog.eventList"
      v-bind="device">
    </dlg-event-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DeviceTable from '@/components/output/DeviceTable.vue';
import CardDeviceInfo from '@/components/card/devicelist/CardDeviceInfo.vue';
import CardDriveInfo from '@/components/card/devicelist/CardDriveInfo.vue';
import CardRunStatus from '@/components/card/devicelist/CardRunStatus.vue';
import CardLocation from '@/components/card/devicelist/CardLocation.vue';
import CardLive2 from '@/components/card/devicelist/CardLive2.vue';
import CardDataUsage from '@/components/card/devicelist/CardDataUsage.vue';
import CardDriveLog from '@/components/card/devicelist/CardDriveLog.vue';
import CardEventRecord from '@/components/card/devicelist/CardEventRecord.vue';
import CardAlarm from '@/components/card/devicelist/CardAlarm.vue';
import DlgEventVideo from '@/components/dialog/DlgEventVideo.vue';
import DlgEventList from '@/components/dialog/DlgEventList.vue';
import util from '@/util';

export default {
  name: 'DeviceList',
  components: {
    DeviceTable,
    CardDriveInfo,
    CardDeviceInfo,
    CardRunStatus,
    CardLocation,
    CardLive2,
    CardDataUsage,
    CardDriveLog,
    CardEventRecord,
    CardAlarm,
    DlgEventVideo,
    DlgEventList,
  },
  data: () => ({
    device: {},
    dialog: {
      eventVideo: false,
      eventList: false,
    },
    drivingLog: {
      show: false,
      start: '',
      end: '',
      identifier: '',
      items: [],
    },
  }),
  computed: {
    ...mapGetters({
      displaySize: 'display/size',
    }),
    listHeight() {
      return this.displaySize.height
        - (this.$vuetify.application.top + this.$vuetify.application.footer);
    },
  },
  watch: {
    device: {
      immediate: true,
      handler(value) {
        if (value.serial != null) {
          this.getDrivingLog();
        }
      },
    },
  },
  sockets: {
    'term.location.driving.log.get': function _(data) {
      if (data.result === 'success') {
        if (this.drivingLog.identifier === data.identifier) {
          this.drivingLog.items = data.items;
        }
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: `[${this.serial}] ${data.message}`,
          color: 'error',
        });
      }
    },
  },
  methods: {
    getDrivingLog() {
      this.drivingLog.start = (() => {
        const date = new Date();
        date.setDate(date.getDate() - 14);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        const isoDate = date.toISOString();
        return `${isoDate.slice(0, 10)} ${isoDate.slice(11, 19)}`;
      })();
      this.drivingLog.end = (() => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        const isoDate = date.toISOString();
        return `${isoDate.slice(0, 10)} ${isoDate.slice(11, 19)}`;
      })();

      this.drivingLog.identifier = util.format.getIdentifier();

      this.$socket.emit('term.location.driving.log.get', {
        serial: this.device.serial,
        start: this.drivingLog.start,
        end: this.drivingLog.end,
        identifier: this.drivingLog.identifier,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.device-card-list {
  margin: -4px;

  .col {
    margin: 4px;
    padding: 0;
    flex-grow: 0;
  }
}
</style>
