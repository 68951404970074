<template>
  <layout-card
    :width="300"
    title="일정 선택 정보">
    <template v-slot:body>
      <div>
        <table class="pl-2 pr-2 card-info-list">
          <tr
            v-for="(item, idx) in info.headers"
            :key="`item_${idx}`">
            <td class="card-info-title">
              {{ item.text }}
            </td>
            <td class="card-info-value">
              {{ info.values[item.value] }}
            </td>
          </tr>
        </table>
        <v-btn
          @click="$emit('click:more')"
          :disabled="select.serial === ''"
          color="#A3A3A3"
          block
          outlined>
          운행이력 보기
        </v-btn>
      </div>
    </template>
  </layout-card>
</template>

<script>
import util from '@/util';
import LayoutCard from '@/components/card/LayoutCard.vue';

export default {
  name: 'CardJournalInfo',
  components: {
    LayoutCard,
  },
  props: {
    select: {
      type: Object,
      required: false,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    info: {
      headers: [
        { text: '이름', value: 'name' },
        { text: '시리얼', value: 'serial' },
        { text: '일정 시작', value: 'start' },
        { text: '일정 종료', value: 'end' },
        { text: '운행 이력', value: 'count' },
      ],
      values: {
        name: '-',
        serial: '-',
        start: '-',
        end: '-',
        count: '-',
      },
    },
    drivingLog: {
      show: false,
      serial: '',
      start: '',
      end: '',
      identifier: null,
    },
  }),
  watch: {
    select: {
      immediate: true,
      handler(value) {
        if (value.serial !== '') {
          this.info.values.serial = this.select.serial;
          this.info.values.name = this.select.name;
          this.info.values.count = `${this.select.count} 건`;
        }
      },
    },
    items: {
      handler(value) {
        this.info.values.start = '-';
        this.info.values.end = '-';

        if (value.length > 0) {
          let { start, end } = value[0];

          value.forEach((item) => {
            start = (item.start != null && item.start < start) ? item.start : start;
            end = (item.end != null && item.end > end) ? item.end : end;
          });

          this.info.values.start = util.time.getLocalDateString(start);
          this.info.values.end = util.time.getLocalDateString(end);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.card-info-list {
  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;

  .card-info-title {
    vertical-align: top;
    color: #989898;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    width: 96px;
  }

  .card-info-value {
    font-size: 1rem;
    font-weight: 800;
    text-align: left;
  }
}
</style>
