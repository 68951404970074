<template>
  <div
    style="position: relative">
    <div
      ref="container"
      :style="{ height: `${height}px` }">
    </div>
    <div
      v-if="useTracking"
      class="toggle-tracking">
      <v-tooltip
        left>
        <template v-slot:activator="{ on, attrs }">
          <button
            @click="tracking = !tracking"
            class="toggle-tracking-btn"
            v-bind="attrs"
            v-on="on">
            <v-icon
              color="#444444"
              size="18">
              {{ (tracking) ? 'mdi-crosshairs-gps' : 'mdi-crosshairs' }}
            </v-icon>
          </button>
        </template>
        <span>위치 추척 토글</span>
      </v-tooltip>
    </div>
    <div
      v-if="useTracking"
      class="toggle-geofence">
      <v-tooltip
        left>
        <template v-slot:activator="{ on, attrs }">
          <button
            @click="geofence.selecting = !geofence.selecting"
            class="toggle-geofence-btn"
            v-bind="attrs"
            v-on="on">
            <v-icon
              color="#444444"
              size="18">
              {{ (geofence.selecting) ? 'mdi-close' : 'mdi-select-marker' }}
            </v-icon>
          </button>
        </template>
        <span>운행 범위 지정</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
/* eslint quotes: ["off"] */
/* eslint operator-linebreak: ["off"] */
/* eslint prefer-template: ["off"] */

import { mapGetters } from 'vuex';
import util from '@/util';

import imgSelectedMarker from '@/assets/selected_marker.png';
import imgStartMarker from '@/assets/start_marker.png';
import imgEndMarker from '@/assets/end_marker.png';

const radiusClearAction = (dataSet, { idClear }) => (
  `<div class="mt-2" ${dataSet}>` +
  `<button ${dataSet} ` +
  `id="${idClear}" ` +
  'type="button" ' +
  'class="mr-2 v-btn v-btn--icon v-btn--round theme--light ' +
  'v-size--small error--text">' +
  '<span class="v-btn__content">' +
  `<i ${dataSet} ` +
  'aria-hidden="true" ' +
  'class="v-icon notranslate mdi mdi-delete-outline theme--light">' +
  '</i>' +
  '</span>' +
  '</button>' +
  '</div>'
);

const radiusContent = (dataSet, args) => (
  `<div class="fence-info-container" ${dataSet}>` +
  `<div ${dataSet}>` +
  `반경 <span class="number">${args.radius}</span>m` +
  '</div>' +
  ((args.drawing) ? radiusClearAction(dataSet, args) : '') +
  '</div>'
);

const menuContentCheck = (dataSet, { idCheck }) => (
  `<div ${dataSet} ` +
  `id="${idCheck}" ` +
  'class="ma-0 pa-0 ' +
  'v-input v-input--hide-details v-input--dense ' +
  'v-input--checkbox v-input--selection-controls ' +
  'theme--light">' +
  '<div class="v-input__control">' +
  '<div class="v-input__slot">' +
  `<div class="v-input--selection-controls__input" ${dataSet}>` +
  '<i aria-hidden="true" class="v-icon notranslate v-icon--dense mdi mdi-checkbox-blank-outline theme--light">' +
  '</i>' +
  '<input aria-checked="false" id="fence-input" role="checkbox" type="checkbox" value="">' +
  '<div class="v-input--selection-controls__ripple">' +
  '</div>' +
  '</div>' +
  '<label ' +
  'for="fence-input" ' +
  'class="v-label theme--light" ' +
  'style="left: 0; right: auto; position: relative;" ' +
  `${dataSet}>` +
  '전역설정' +
  '</label>' +
  '</div>' +
  '</div>' +
  '</div>'
);

const menuContentAction = (dataSet, { idAccept, idCancel }) => (
  `<button ${dataSet} ` +
  `id="${idAccept}" ` +
  'type="button" ' +
  'class="mr-2 v-btn v-btn--icon v-btn--round theme--light ' +
  'v-size--small primary--text">' +
  '<span class="v-btn__content">' +
  `<i ${dataSet} ` +
  'aria-hidden="true" ' +
  'class="v-icon notranslate mdi mdi-check theme--light">' +
  '</i>' +
  '</span>' +
  '</button>' +
  `<button ${dataSet} ` +
  `id="${idCancel}" ` +
  'type="button" ' +
  'class="v-btn v-btn--icon v-btn--round theme--light ' +
  'v-size--small error--text">' +
  '<span class="v-btn__content">' +
  `<i ${dataSet} ` +
  'aria-hidden="true" ' +
  'class="v-icon notranslate mdi mdi-close theme--light">' +
  '</i>' +
  '</span>' +
  '</button>'
);

const menuContent = (dataSet, args) => (
  `<div class="fence-info-container" ${dataSet}>` +
  `<div class="fence-info-content" ${dataSet}>` +
  `반경 <span class="number">${args.radius}</span>m` +
  '</div>' +
  `<div class="fence-info-content" ${dataSet}>` +
  menuContentCheck(dataSet, args) +
  '</div>' +
  `<div class="fence-info-action" ${dataSet}>` +
  menuContentAction(dataSet, args) +
  '</div>' +
  '</div>'
);

export default {
  name: 'LocationMap',
  props: {
    height: {
      type: Number,
      default: 600,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    useTracking: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    map: null,
    markers: {},
    icon: {
      selected: imgSelectedMarker,
      start: imgStartMarker,
      end: imgEndMarker,
    },
    path: {
      startMarker: null,
      endMarker: null,
      line: null,
    },
    firstLoading: false,
    tracking: false,
    geofence: {
      selecting: false,
      drawing: false,
      ids: {
        globalCheck: 'fence-check-container',
        btnAccept: 'fence-action-accpet',
        btnCancel: 'fence-action-cancel',
        btnClear: 'fence-action-clear',
      },
      draw: {
        centerPosition: 0,
        line: new window.kakao.maps.Polyline({
          strokeWeight: 3,
          strokeColor: '#00a0e9',
          strokeOpacity: 1,
          strokeStyle: 'solid',
        }),
        circle: new window.kakao.maps.Circle({
          strokeWeight: 1,
          strokeColor: '#00a0e9',
          strokeOpacity: 0.1,
          strokeStyle: 'solid',
          fillColor: '#00a0e9',
          fillOpacity: 0.2,
        }),
        overlay: new window.kakao.maps.CustomOverlay({
          xAnchor: -0.12,
          yAnchor: 0,
          zIndex: 1,
          clickable: true,
        }),
      },
      clickHandler: null,
      moveHandler: null,
      info: {
        global: false,
        radius: 0,
        location: {
          La: 0,
          Ma: 0,
        },
      },
    },
  }),
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
      selectedDevice: 'devices/select',
      locationTasks: 'location/tasks',
      locationFlag: 'location/flag',
      coordinates: 'location/coordinates',
      level: 'location/level',
      locationLog: 'location/locationLog',
    }),
  },
  watch: {
    devices: {
      immediate: false,
      handler() {
        this.addMarkers();

        if (this.tracking) {
          this.doTracking();
        }

        if (this.geofence.selecting) {
          this.updateFence();
        }
      },
    },
    selectedDevice: {
      handler() {
        this.addMarkers();

        if (this.geofence.selecting) {
          this.updateFence();
        }
      },
    },
    locationFlag: {
      handler(flag) {
        if (flag) {
          this.$store.dispatch('location/flag', false);

          if (this.locationTasks.length > 0) {
            const { type, value } = this.locationTasks[0];
            this.$store.commit('location/popTask');

            switch (type) {
              case 'move': {
                if (!this.movePosition(value)) {
                  this.$nextTick(() => {
                    this.$store.commit('location/flag', true);
                  });
                }
                break;
              }
              case 'level': {
                if (value !== this.map.getLevel()) {
                  this.map.setLevel(value);
                } else {
                  this.$nextTick(() => {
                    this.$store.commit('location/flag', true);
                  });
                }
                break;
              }
              case 'tracking': {
                this.tracking = value;
                this.$nextTick(() => {
                  this.$store.commit('location/flag', true);
                });
                break;
              }
              default:
                break;
            }
          }
        }
      },
    },
    locationLog: {
      immediate: true,
      handler() {
        if (this.path.line != null) {
          this.path.line.setMap(null);
          this.path.line = null;
        }

        if (this.path.startMarker != null) {
          this.path.startMarker.setMap(null);
          this.path.startMarker = null;
        }

        if (this.path.endMarker != null) {
          this.path.endMarker.setMap(null);
          this.path.endMarker = null;
        }

        if (this.locationLog.items.length > 0) {
          const path = this.locationLog.items.map((item) => (
            new window.kakao.maps.LatLng(item.coordinates.lat, item.coordinates.lng)
          ));

          this.path.line = new window.kakao.maps.Polyline({
            path,
            strokeWeight: 5,
            strokeColor: '#c71717',
            strokeOpacity: 0.7,
            strokeStyle: 'solid',
            endArrow: true,
          });

          this.path.startMarker = new window.kakao.maps.Marker({
            position: new window.kakao.maps.LatLng(
              this.locationLog.items[0].coordinates.lat,
              this.locationLog.items[0].coordinates.lng,
            ),
            image: new window.kakao.maps.MarkerImage(
              this.icon.start,
              new window.kakao.maps.Size(42, 42),
              { offset: new window.kakao.maps.Point(21, 42) },
            ),
            zIndex: 2,
          });

          const lastIdx = this.locationLog.items.length - 1;
          this.path.endMarker = new window.kakao.maps.Marker({
            position: new window.kakao.maps.LatLng(
              this.locationLog.items[lastIdx].coordinates.lat,
              this.locationLog.items[lastIdx].coordinates.lng,
            ),
            image: new window.kakao.maps.MarkerImage(
              this.icon.end,
              new window.kakao.maps.Size(42, 42),
              { offset: new window.kakao.maps.Point(21, 42) },
            ),
            zIndex: 3,
          });

          this.path.startMarker.setMap(this.map);
          this.path.endMarker.setMap(this.map);
          this.path.line.setMap(this.map);

          // 지도 레벨 변경
          const bounds = new window.kakao.maps.LatLngBounds();
          path.forEach((pos) => {
            bounds.extend(pos);
          });
          this.map.setBounds(bounds);
        }
      },
    },
    tracking: {
      immediate: true,
      handler(value) {
        if (value) {
          this.doTracking();
        }
      },
    },
    'geofence.selecting': {
      immediate: false,
      handler(value) {
        if (value) {
          // 선택 중인 장치의 현재 설정 범위 표시
          this.updateFence();

          // 클릭 이벤트 정의
          this.geofence.clickHandler = (event) => {
            if (!this.geofence.drawing) {
              // 기존 레이아웃 제거
              this.geofence.draw.circle.setMap(null);
              this.geofence.draw.line.setMap(null);
              this.geofence.draw.overlay.setMap(null);

              this.geofence.drawing = true;

              // 중심 좌표 선택
              this.geofence.draw.centerPosition = event.latLng;

              this.geofence.info.location = {
                lng: event.latLng.La,
                lat: event.latLng.Ma,
              };
            } else {
              const radius = Math.round(this.geofence.draw.circle.getRadius());

              this.geofence.info.radius = radius;

              this.geofence.draw.overlay.setContent(menuContent(
                util.vue.dataSet(this),
                {
                  radius,
                  idCheck: this.geofence.ids.globalCheck,
                  idAccept: this.geofence.ids.btnAccept,
                  idCancel: this.geofence.ids.btnCancel,
                },
              ));

              this.createFenceSetHandler();

              this.geofence.draw.centerPosition = null;
              this.geofence.drawing = false;
            }
          };

          window.kakao.maps.event.addListener(this.map, 'click', this.geofence.clickHandler);

          // 마우스 이동 이벤트 정의
          this.geofence.moveHandler = (event) => {
            if (this.geofence.drawing) {
              const mousePosition = event.latLng;

              this.geofence.draw.line.setPath([
                this.geofence.draw.centerPosition,
                mousePosition,
              ]);

              const length = this.geofence.draw.line.getLength();

              if (length > 0) {
                this.geofence.draw.circle.setOptions({
                  center: this.geofence.draw.centerPosition,
                  radius: length,
                });

                const radius = Math.round(this.geofence.draw.circle.getRadius());

                this.geofence.draw.overlay.setPosition(mousePosition);
                this.geofence.draw.overlay.setContent(radiusContent(
                  util.vue.dataSet(this),
                  {
                    radius,
                    drawing: false,
                  },
                ));

                this.geofence.draw.circle.setMap(this.map);
                this.geofence.draw.line.setMap(this.map);
                this.geofence.draw.overlay.setMap(this.map);
              } else {
                this.geofence.draw.circle.setMap(null);
                this.geofence.draw.line.setMap(null);
                this.geofence.draw.overlay.setMap(null);
              }
            }
          };

          window.kakao.maps.event.addListener(this.map, 'mousemove', this.geofence.moveHandler);
        } else {
          // 범위 표시 제거
          this.clearFence();

          // 이벤트 제거
          if (this.geofence.clickHandler != null) {
            window.kakao.maps.event.removeListener(this.map, 'click', this.geofence.clickHandler);
            this.geofence.clickHandler = null;
          }

          if (this.geofence.moveHandler != null) {
            window.kakao.maps.event.removeListener(this.map, 'mousemove', this.geofence.moveHandler);
            this.geofence.moveHandler = null;
          }
        }
      },
    },
  },
  mounted() {
    this.initMap();
    this.addMarkers();
  },
  beforeDestroy() {
    this.releaseMap();
  },
  sockets: {
    'term.device.fence.set': function _(data) {
      if (data.result === 'success') {
        // 범위 표시
        this.$store.dispatch('alert/setAlert', {
          color: 'primary',
          message: '범위 지정이 완료되었습니다.',
        });

        // 장치 정보 다시 가져오기
        this.$store.dispatch('devices/getDevices');
      } else {
        this.$store.dispatch('alert/setAlert', {
          color: 'error',
          message: data.message,
        });
      }
    },
    'term.device.fence.clear': function _(data) {
      if (data.result === 'success') {
        // 범위 표시
        this.$store.dispatch('alert/setAlert', {
          color: 'primary',
          message: '범위 설정이 제거되었습니다.',
        });

        // 장치 정보 다시 가져오기
        this.$store.dispatch('devices/getDevices');
      } else {
        this.$store.dispatch('alert/setAlert', {
          color: 'error',
          message: data.message,
        });
      }
    },
  },
  methods: {
    initMap() {
      if (this.map == null) {
        this.map = new window.kakao.maps.Map(this.$refs.container, {
          center: new window.kakao.maps.LatLng(this.coordinates.lat, this.coordinates.lng),
          level: this.level,
        });

        this.map.addControl(
          new window.kakao.maps.ZoomControl(),
          window.kakao.maps.ControlPosition.RIGHT,
        );

        window.kakao.maps.event.addListener(
          this.map,
          'zoom_changed',
          this.zoomChanged,
        );

        window.kakao.maps.event.addListener(
          this.map,
          'dragend',
          this.dragend,
        );
      }
    },
    releaseMap() {
      window.kakao.maps.event.removeListener(
        this.map,
        'zoom_changed',
        this.zoomChanged,
      );

      window.kakao.maps.event.removeListener(
        this.map,
        'dragend',
        this.dragend,
      );
    },
    zoomChanged() {
      this.$nextTick(() => {
        this.$store.commit('location/level', this.map.getLevel());
        this.$store.dispatch('location/flag', true);
      });
    },
    dragend() {
      this.$nextTick(() => {
        const pos = this.map.getCenter();
        this.$store.commit('location/coordinates', {
          lat: pos.getLat(),
          lng: pos.getLng(),
        });
        this.$store.dispatch('location/flag', true);
      });
    },
    addMarkers() {
      if (this.devices.length > 0) {
        let selected = null;

        const boundPoint = [
          { lat: 999, lng: 999 },
          { lat: -999, lng: -999 },
        ];

        this.devices.forEach((item) => {
          if (item.gps != null && item.gps.latitude !== 0) {
            const location = util.location.gpsToCoordinates(item.gps);

            // 위도/경도 최대/최소 값 구하기
            boundPoint[0].lat = Math.min(location.lat, boundPoint[0].lat);
            boundPoint[0].lng = Math.min(location.lng, boundPoint[0].lng);
            boundPoint[1].lat = Math.max(location.lat, boundPoint[1].lat);
            boundPoint[1].lng = Math.max(location.lng, boundPoint[1].lng);

            if (item.serial !== this.devices[this.selectedDevice].serial) {
              this.addMarker(item);
            } else {
              selected = item;
            }
          }
        });

        if (selected != null && selected.gps != null && selected.gps.latitude !== 0) {
          this.addMarker(selected);

          // 지도 밖에 위치해 있으면 해당 위치로 이동
          const position = util.location.gpsToPosition(selected.gps);
          const extBounds = this.extendBounds(this.map.getBounds(), this.map.getProjection());
          if (!extBounds.contain(position)) {
            this.$store.dispatch('location/move', {
              coordinates: util.location.gpsToCoordinates(selected.gps),
            });
          }
        }

        if (this.firstLoading) {
          // 지도 레벨 변경
          const bounds = new window.kakao.maps.LatLngBounds();
          bounds.extend(new window.kakao.maps.LatLng(boundPoint[0].lat, boundPoint[0].lng));
          bounds.extend(new window.kakao.maps.LatLng(boundPoint[1].lat, boundPoint[1].lng));

          this.map.setBounds(bounds);

          this.firstLoading = false;
        }
      }
    },
    addMarker(item) {
      if (this.markers[item.serial] != null) {
        this.markers[item.serial].overlay.setMap(null);
        this.markers[item.serial].marker.setMap(null);

        this.markers[item.serial] = null;
      }

      const position = util.location.gpsToPosition(item.gps);

      const content = `<div class="map-overlay-container"><span>${item.name}</span></div>`;
      const overlay = new window.kakao.maps.CustomOverlay({
        map: this.map,
        position,
        content,
        yAnchor: 1,
      });

      let image = null;

      if (item.serial === this.devices[this.selectedDevice].serial) {
        image = new window.kakao.maps.MarkerImage(
          this.icon.selected,
          new window.kakao.maps.Size(42, 42),
          { offset: new window.kakao.maps.Point(21, 42) },
        );
      }

      this.markers[item.serial] = {
        marker: new window.kakao.maps.Marker({
          position,
          image,
          zIndex: 1,
        }),
        overlay,
      };

      this.markers[item.serial].marker.setMap(this.map);
    },
    movePosition({ lat, lng }) {
      let changed = false;
      const pos = this.map.getCenter();

      if (pos.lat !== lat || pos.lng !== lng) {
        this.map.panTo(new window.kakao.maps.LatLng(lat, lng));
        changed = true;
      }

      return changed;
    },
    doTracking() {
      const device = this.devices[this.selectedDevice];
      this.movePosition(util.location.gpsToCoordinates(device.gps));
    },
    extendBounds(bounds, proj) {
      // 주어진 bounds는 지도 좌표 정보로 표현되어 있습니다.
      // 이것을 BOUNDS_BUFFER 픽셀 만큼 확장하기 위해서는
      // 픽셀 단위인 화면 좌표로 변환해야 합니다.
      const BOUNDS_BUFFER = 30;
      const sw = proj.pointFromCoords(bounds.getSouthWest());
      const ne = proj.pointFromCoords(bounds.getNorthEast());

      // 확장을 위해 각 좌표에 BOUNDS_BUFFER가 가진 수치만큼 더하거나 빼줍니다.
      sw.x -= BOUNDS_BUFFER;
      sw.y += BOUNDS_BUFFER;

      ne.x += BOUNDS_BUFFER;
      ne.y -= BOUNDS_BUFFER;

      // 그리고나서 다시 지도 좌표로 변환한 extBounds를 리턴합니다.
      // extBounds는 기존의 bounds에서 상하좌우 30px만큼 확장된 영역 객체입니다.
      return new window.kakao.maps.LatLngBounds(
        proj.coordsFromPoint(sw),
        proj.coordsFromPoint(ne),
      );
    },
    createFenceSetHandler() {
      // 값 초기화
      this.geofence.info.global = false;

      const globalCheckContainer = document.getElementById(this.geofence.ids.globalCheck);
      const globalCheck = globalCheckContainer.getElementsByTagName('input')[0];
      const globalCheckRipple = globalCheckContainer.getElementsByClassName('v-input--selection-controls__ripple')[0];

      const handler = (e) => {
        if (e.target !== globalCheck) {
          globalCheck.checked = !globalCheck.checked;
        }

        // 클래스 추가 및 제거
        const check = globalCheckContainer;
        const icon = check.getElementsByClassName('v-icon')[0];

        this.geofence.info.global = globalCheck.checked;

        if (globalCheck.checked) {
          check.classList.add(
            'v-input--is-label-active',
            'v-input--is-dirty',
            'primary--text',
          );
          icon.classList.replace(
            'mdi-checkbox-blank-outline',
            'mdi-checkbox-marked',
          );
          icon.classList.add('primary--text');
        } else {
          check.classList.remove(
            'v-input--is-label-active',
            'v-input--is-dirty',
            'primary--text',
          );
          icon.classList.replace(
            'mdi-checkbox-marked',
            'mdi-checkbox-blank-outline',
          );
          icon.classList.remove('primary--text');
        }
      };

      globalCheck.addEventListener('change', handler);
      globalCheckRipple.addEventListener('click', handler);

      // 버튼 동작 정의
      document.getElementById(this.geofence.ids.btnAccept).addEventListener('click', () => {
        console.log(
          'click Accept:',
          this.geofence.info.global,
          this.geofence.info.radius,
          this.geofence.info.location,
        );

        this.$socket.emit('term.device.fence.set', {
          global: this.geofence.info.global,
          serial: this.devices[this.selectedDevice].serial,
          data: {
            radius: this.geofence.info.radius,
            location: this.geofence.info.location,
          },
        });

        this.clearRadiusMenu();
      });

      document.getElementById(this.geofence.ids.btnCancel).addEventListener('click', () => {
        console.log('click Cancel');
        this.clearRadiusMenu();
        this.updateFence();
      });
    },
    createFenceClearHandler() {
      // 버튼 동작 정의
      document.getElementById(this.geofence.ids.btnClear).addEventListener('click', () => {
        console.log(
          'click Clear:',
          this.devices[this.selectedDevice].serial,
        );

        this.$socket.emit('term.device.fence.clear', {
          serial: this.devices[this.selectedDevice].serial,
        });
      });
    },
    clearRadiusMenu() {
      this.geofence.draw.circle.setMap(null);
      this.geofence.draw.line.setMap(null);
      this.geofence.draw.overlay.setMap(null);
    },
    updateFence() {
      const device = this.devices[this.selectedDevice];
      const { fenceData } = device;

      console.log('fenceData:', fenceData);

      if (fenceData != null) {
        // 반경 그리기
        this.geofence.draw.centerPosition = new window.kakao.maps.LatLng(
          fenceData.location.lat,
          fenceData.location.lng,
        );
        this.geofence.info.radius = fenceData.radius;

        this.geofence.draw.circle.setOptions({
          center: this.geofence.draw.centerPosition,
          radius: this.geofence.info.radius,
        });

        // 라인 그리기
        const bounds = this.geofence.draw.circle.getBounds();

        console.log('bounds:', bounds);

        const layoutPos = new window.kakao.maps.LatLng(
          bounds.qa,
          bounds.oa - ((bounds.oa - bounds.ha) / 2),
        );

        this.geofence.draw.line.setPath([
          this.geofence.draw.centerPosition,
          layoutPos,
        ]);

        this.geofence.draw.overlay.setPosition(layoutPos);
        this.geofence.draw.overlay.setContent(radiusContent(
          util.vue.dataSet(this),
          {
            radius: this.geofence.info.radius,
            drawing: true,
            idClear: this.geofence.ids.btnClear,
          },
        ));

        this.geofence.draw.circle.setMap(this.map);
        this.geofence.draw.line.setMap(this.map);
        this.geofence.draw.overlay.setMap(this.map);

        this.createFenceClearHandler();
      } else {
        this.clearFence();
      }
    },
    clearFence() {
      console.log('clearFence');

      // 반경 표시 객체를 맵에서 제거
      this.geofence.draw.circle.setMap(null);
      this.geofence.draw.line.setMap(null);
      this.geofence.draw.overlay.setMap(null);
    },
  },
};
</script>

<style scoped lang="scss">
.center-marker {
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: #ffffff;
  border: #ff0000 solid 2px;
  border-radius: 10px;
}
</style>

<style lang="scss">
.map-overlay-container {
  background-color: white;
  padding: 8px;
  position:relative;
  bottom:55px;
  border-radius:6px;
  border: 1px solid #ccc;
  border-bottom:2px solid #ddd;
  float:left;

  &:nth-of-type(n) {
    border:0;
    box-shadow:0 1px 2px #888;
  }

  &:after {
    content:'';
    position:absolute;
    margin-left:-12px;
    left:50%;
    bottom:-12px;
    width:22px;
    height:12px;
    background:url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png')
  }
}

.toggle-tracking {
  position: absolute;
  top: 193px;
  right: 3px;
  overflow: hidden;
  z-index:1;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 2px 0;

  &-btn {
    float: left;
    cursor: pointer;
    width: 32px;
    height: 32px;
    user-select: none;
    border-style: solid;
    border-width: 1px;
    background: rgb(255, 255, 255);
    border-radius: 3px 3px 3px 3px;
  }
}

.toggle-geofence {
  position: absolute;
  top: 228px;
  right: 3px;
  overflow: hidden;
  z-index:1;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 2px 0;

  &-btn {
    float: left;
    cursor: pointer;
    width: 32px;
    height: 32px;
    user-select: none;
    border-style: solid;
    border-width: 1px;
    background: rgb(255, 255, 255);
    border-radius: 3px 3px 3px 3px;
  }
}
</style>

<style scoped lang="scss">
.fence-info-container {
  display: flex;
  flex-direction: column;
  padding: .5rem;
  top: 5px;
  left: 5px;
  border-radius: 6px;
  border: 1px solid #ccc;
  border-bottom: 2px solid #ddd;
  background:#fff;
  font-size: 12px;
  margin:0;

  .fence-info-content {
    margin-bottom: .5rem;
  }

  .fence-info-action {
  }
}

.v-input {
  .v-label {
    font-size: 12px;
  }
}
</style>
