<template>
  <layout-accent-card
    title="운행 이력"
    :value="count"
    prepend-string="오늘"
    append-string="건"
    :icon="icon"
    :width="300"
    :height="200">
    <template v-slot:extension>
      <div class="btn-container">
        <v-btn
          @click="drivingLog.show = true"
          color="#A3A3A3"
          outlined
          block>
          이력보기
        </v-btn>
      </div>
      <dlg-driving-log
        v-if="drivingLog.show"
        :init-items="drivingLog.items"
        :show.sync="drivingLog.show"
        :serial="serial"
        :name="name"
        :start="drivingLog.start"
        :end="drivingLog.end">
      </dlg-driving-log>
    </template>
  </layout-accent-card>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutAccentCard from '@/components/card/LayoutAccentCard.vue';
import DlgDrivingLog from '@/components/dialog/DlgDrivingLog.vue';

import Icon from '@/assets/icon_11.svg';

export default {
  name: 'CardDriveLog',
  components: {
    LayoutAccentCard,
    DlgDrivingLog,
  },
  props: {
    serial: {
      type: String,
      default: '-',
    },
    name: {
      type: String,
      default: '-',
    },
    drivingLog: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    icon: Icon,
    today: (() => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    })(),
  }),
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
    }),
    count() {
      let cnt = 0;

      for (let idx = this.drivingLog.items.length - 1; idx >= 0; idx -= 1) {
        const time = new Date(this.drivingLog.items[idx].start);

        if (time < this.today) {
          break;
        }

        cnt += 1;
      }

      return cnt;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  margin-top: 24px;
  width: 100%;
}
</style>
