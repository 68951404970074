import Events from 'events';
import cloneDeep from 'lodash.clonedeep';
import XLSX from 'sheetjs-style';
import util from '@/util';

import templateFile from '@/assets/driving_log_template.xlsx';

function setCell(sheet, address, value) {
  const ref = sheet[address];
  ref.v = value;
  ref.t = 's';
}

function setCellStyle(sheet, address, name, value) {
  const ref = sheet[address];

  const attr = (name === 'font') ? { name: '맑은 고딕', ...value } : value;

  ref.s = {
    ...ref.s,
    [name]: attr,
  };

  ref.t = (ref.t !== 'z') ? ref.t : 's';
  ref.v = (ref.v != null) ? ref.v : '';
}

class ExportJournal {
  event = new Events();

  initialized = false;

  wb = null;

  newBook = null;

  sampleSheet = null;

  manualSheet = null;

  sheets = new Map();

  style = {
    font: {
      name: '돋움',
    },
  };

  constructor() {
    this.loadTemplateFile()
      .then(() => this.loadSampleSheet())
      .then(() => this.loadManualSheet())
      .then(() => this.implementSheetStyle())
      .then(() => this.implementSheetBorder())
      .finally(() => {
        this.initialized = true;
        this.event.emit('init');
      });
  }

  loadTemplateFile() {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binary = event.target.result;
        const wb = XLSX.read(binary, {
          type: 'binary',
          bookSST: true,
          cellStyles: true,
          sheetStubs: true,
        });

        resolve(true);

        this.wb = wb;
        this.newBook = XLSX.utils.book_new();
      };

      fetch(templateFile)
        .then((res) => res.blob())
        .then((blob) => {
          reader.readAsBinaryString(blob);
        });
    });
  }

  loadSampleSheet() {
    const name = this.wb.SheetNames[0];
    this.sampleSheet = this.wb.Sheets[name];
  }

  loadManualSheet() {
    const name = this.wb.SheetNames[1];
    this.manualSheet = this.wb.Sheets[name];
  }

  implementSheetStyle() {
    const { sampleSheet } = this;

    // 머릿말
    setCellStyle(sampleSheet, 'B1', 'font', { name: '맑은 고딕', sz: 10 });
    setCellStyle(sampleSheet, 'B1', 'alignment', {
      vertical: 'center',
      horizontal: 'left',
    });

    // Main Title
    setCellStyle(sampleSheet, 'H2', 'font', { sz: 18, bold: true });
    setCellStyle(sampleSheet, 'H2', 'alignment', {
      vertical: 'center',
      horizontal: 'center',
    });

    // Title
    const title = ['B7', 'B11'];
    title.forEach((item) => {
      setCellStyle(sampleSheet, item, 'font', { sz: 12, bold: true });
      setCellStyle(sampleSheet, item, 'alignment', {
        vertical: 'center',
        wrapText: true,
      });
    });

    // Label
    const label = [
      'B2', 'D2', 'D3', 'D5', 'N2', 'N4', 'B2', 'B8', 'E8',
      'B12', 'C12', 'G12', 'C13', 'E13', 'G13', 'I13', 'K13',
      'L13', 'Q13', 'L14', 'O14', 'G78', 'L78', 'Q78',
    ];
    label.forEach((item) => {
      setCellStyle(sampleSheet, item, 'font', { sz: 11 });
      setCellStyle(sampleSheet, item, 'alignment', {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      });
    });

    // 값 영역 (기타)
    ['B', 'C', 'E', 'G', 'I', 'L', 'O', 'Q'].forEach((item) => {
      for (let idx = 15; idx <= 77; idx += 1) {
        setCellStyle(sampleSheet, `${item}${idx}`, 'font', { sz: 11 });
        setCellStyle(sampleSheet, `${item}${idx}`, 'alignment', {
          vertical: 'center',
          horizontal: 'center',
        });
      }
    });

    // 주행거리
    for (let idx = 15; idx <= 77; idx += 1) {
      setCellStyle(sampleSheet, `K${idx}`, 'font', { sz: 11 });
      setCellStyle(sampleSheet, `K${idx}`, 'alignment', {
        vertical: 'center',
        horizontal: 'right',
      });
    }
  }

  implementSheetBorder() {
    const { sampleSheet } = this;

    const borders = [
      { row: 1, cols: { start: 1, end: 16 }, lines: ['top'] },
      { row: 4, cols: { start: 1, end: 16 }, lines: ['bottom'] },
      { row: 7, cols: { start: 1, end: 7 }, lines: ['top'] },
      { row: 8, cols: { start: 1, end: 7 }, lines: ['top', 'bottom'] },
      { row: 11, cols: { start: 1, end: 16 }, lines: ['top', 'bottom'] },
      { row: 12, cols: { start: 11, end: 15 }, lines: ['bottom'] },
      { row: 13, cols: { start: 1, end: 16 }, lines: ['bottom'] },
    ];

    borders.forEach((info) => {
      for (let col = info.cols.start; col <= info.cols.end; col += 1) {
        const style = {};
        const cell = XLSX.utils.encode_cell({
          r: info.row,
          c: col,
        });

        info.lines.forEach((line) => {
          style[line] = { style: 'thin', color: 'FFFFFFFF' };
        });

        setCellStyle(sampleSheet, cell, 'border', style);
      }
    });
  }

  writeData({ serial, data }) {
    const sheetName = `${data.name}(${serial})`;
    XLSX.utils.book_append_sheet(
      this.newBook,
      cloneDeep(this.sampleSheet),
      sheetName,
    );

    if (data.items.length > 0) {
      this.sheets.set(serial, this.newBook.Sheets[sheetName]);

      const sheet = this.sheets.get(serial);

      data.items.forEach((item, idx) => {
        const date = util.time.getLocalDate(item.start).format('MM/DD');
        const distance = Math.floor(item.distance / 100) / 10;

        const row = idx + 15;
        setCell(sheet, `B${row}`, date);
        setCell(sheet, `K${row}`, distance);
      });
    }
  }

  storeFile() {
    XLSX.utils.book_append_sheet(this.newBook, this.manualSheet, '작성방법');
    const fileName = (this.newBook.SheetNames.length > 2)
      ? '운행기록부(전체).xlsx'
      : '운행기록부.xlsx';
    XLSX.writeFile(this.newBook, fileName, { type: 'binary' });
  }

  on(event, cb) {
    this.event.on(event, cb);
  }
}

export default {
  ExportJournal,
};
