<template>
  <div
    class="d-flex">
    <div
      class="flex-grow-1">
      <card-history-table
        @dblclick="drivingLog.show = true"
        :select.sync="select"
        :height="listHeight - 161">
      </card-history-table>
    </div>
    <div>
      <div
        class="ml-4 d-flex flex-column">
        <div>
          <card-journal-info
            @click:more="drivingLog.show = true"
            :select="select"
            :items="items">
          </card-journal-info>
        </div>
      </div>
    </div>
    <dlg-driving-log
      v-if="drivingLog.show"
      :init-items="items"
      :show.sync="drivingLog.show"
      :serial="select.serial"
      :name="select.name"
      :start="drivingLog.start"
      :end="drivingLog.end">
    </dlg-driving-log>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import util from '@/util';
import CardHistoryTable from '@/components/card/drivinghistory/CardJournalTable.vue';
import CardJournalInfo from '@/components/card/drivinghistory/CardJournalInfo.vue';
import DlgDrivingLog from '@/components/dialog/DlgDrivingLog.vue';

export default {
  name: 'DrivingHistory',
  components: {
    CardJournalInfo,
    CardHistoryTable,
    DlgDrivingLog,
  },
  data: () => ({
    select: {
      serial: '',
      name: '',
      date: '',
    },
    drivingLog: {
      show: false,
      serial: '',
      start: '',
      end: '',
      identifier: null,
    },
    items: [],
  }),
  computed: {
    ...mapGetters({
      displaySize: 'display/size',
    }),
    listHeight() {
      return this.displaySize.height
        - (this.$vuetify.application.top + this.$vuetify.application.footer);
    },
  },
  watch: {
    select: {
      immediate: true,
      handler(value) {
        if (value.serial !== '') {
          const start = util.time.getUtcDate(value.date);
          const end = util.time.getUtcDate(value.date);
          end.date(end.date() + 1);

          this.drivingLog.start = start.format('YYYY-MM-DD HH:mm:ss');
          this.drivingLog.end = end.format('YYYY-MM-DD HH:mm:ss');

          this.getDrivingLog();
        }
      },
    },
  },
  sockets: {
    'term.location.driving.log.get': function _(data) {
      if (data.result === 'success') {
        if (this.drivingLog.identifier === data.identifier) {
          this.items = data.items;
        }
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: `[${this.serial}] ${data.message}`,
          color: 'error',
        });
      }
    },
  },
  methods: {
    getDrivingLog() {
      this.drivingLog.identifier = util.format.getIdentifier();

      this.$socket.emit('term.location.driving.log.get', {
        serial: this.select.serial,
        start: this.drivingLog.start,
        end: this.drivingLog.end,
        identifier: this.drivingLog.identifier,
      });
    },
  },
};
</script>

<style scoped>

</style>
