<template>
  <layout-card
    :width="300"
    :height="200"
    title="위치정보"
    prepend-string="오늘"
    append-string="대"
    :icon="icon">
    <template v-slot:body>
      <div class="d-flex flex-row">
        <div class="pr-2 pt-4 pb-4">
          <v-avatar
            color="#e4eff1"
            size="88px">
            <v-icon
              color="#9CBED1"
              size="64">
              mdi-map-marker-radius
            </v-icon>
          </v-avatar>
        </div>
        <div class="pa-2 d-flex flex-column flex-grow-1">
          <div class="d-flex pb-4 card-info-value flex-grow-1">
            <div class="ma-auto">
              {{ address }}
            </div>
          </div>
          <div
            class="mt-auto">
            <v-btn
              @click="$router.push('/location')"
              color="#A3A3A3"
              block
              outlined>
              지도 보기
            </v-btn>
          </div>
        </div>
      </div>
    </template>
  </layout-card>
</template>

<script>
import util from '@/util';
import LayoutCard from '@/components/card/LayoutCard.vue';

import Icon from '@/assets/icon_01.svg';

export default {
  name: 'CardLocation',
  components: {
    LayoutCard,
  },
  props: {
    gps: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    icon: Icon,
    geocoder: new window.kakao.maps.services.Geocoder(),
    address: '-',
  }),
  watch: {
    gps: {
      immediate: true,
      handler() {
        if (this.gps != null) {
          const position = util.location.gpsToPosition(this.gps);

          this.searchDetailAddrFromCoords(position, (result, status) => {
            if (status === window.kakao.maps.services.Status.OK) {
              this.address = (result[0].road_address != null)
                ? result[0].road_address.address_name
                : result[0].address.address_name;
            } else {
              this.address = '-';
            }
          });
        } else {
          this.address = '-';
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    searchDetailAddrFromCoords(position, cb) {
      this.geocoder.coord2Address(position.getLng(), position.getLat(), cb);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-info-value {
  color: #989898;
  font-weight: bold;
  text-align: left;
  word-break: keep-all;
}
</style>
