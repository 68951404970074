<template>
  <layout-card
    title="지오펜스 알림 조건"
    :width="260"
    :min-width="200"
    :height="200">
    <template v-slot:body>
      <div>
        <v-select
          @change="setFenceType(type)"
          v-model="type"
          :loading="loading"
          :disabled="loading"
          :items="items">
        </v-select>
      </div>
    </template>
  </layout-card>
</template>

<script>
import LayoutCard from '@/components/card/LayoutCard.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CardFenceType',
  components: {
    LayoutCard,
  },
  data: () => ({
    items: [
      { value: 0, text: '없음' },
      { value: 1, text: '범위 벗어나면 알림' },
      { value: 2, text: '범위 들어오면 알림' },
      { value: 3, text: '모두 알림' },
    ],
    type: 0,
    loading: true,
  }),
  computed: {
    ...mapGetters({
      loggedIn: 'user/loggedIn',
    }),
  },
  watch: {
    loggedIn: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getFenceType();
        }
      },
    },
  },
  sockets: {
    'term.device.fence.type.get': function _(data) {
      if (data.result === 'success') {
        this.type = data.type;
        this.loading = false;
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        });
      }
    },
    'term.device.fence.type.set': function _(data) {
      if (data.result === 'success') {
        this.loading = false;
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        });
      }
    },
  },
  methods: {
    getFenceType() {
      this.$socket.emit('term.device.fence.type.get', {});
    },
    setFenceType(type) {
      console.log('type:', type);
      this.$socket.emit('term.device.fence.type.set', { type });
    },
  },
};
</script>

<style scoped>

</style>
