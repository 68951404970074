<template>
  <div
    v-resize="onResize"
    ref="container">
    <div>
      <svg
        :width="realSize.width + 17"
        :height="cellSize">
        <g
          transform="translate(107, 0)">
          <g
            v-for="day in days"
            :key="`day_label_${day}`"
            class="table-day-label"
            :class="calcDayLabelClass(day)">
            <rect
              :x="((day - 1) * (cellSize + 7)) + 3"
              y="0"
              :width="cellSize"
              :height="cellSize">
            </rect>
            <text
              :x="((day - 1) * (cellSize + 7)) + ((cellSize / 2) + 3)"
              :y="(cellSize / 2) + 2">
              {{ day }}
            </text>
          </g>
        </g>
      </svg>
    </div>
    <div
      :style="{ height: `${height}px` }"
      style="overflow-y: scroll; overflow-x: hidden;">
      <svg
        :width="realSize.width"
        :height="realSize.height">
        <g
          transform="translate(0, 0)">
          <g
            v-for="([serial, item], row) in Object.entries(items)"
            :key="`row_${row}`">
            <g
              class="table-label"
              :class="calcLabelClass(serial)">
              <rect
                x="0"
                :y="(row * (cellSize + 7)) + 1"
                :width="103"
                :height="cellSize">
              </rect>
              <text
                x="5"
                :y="(row * (cellSize + 7)) + (cellSize / 2) + 4">
                {{ item.name }}
              </text>
            </g>
            <rect
              @dblclick="$emit('dblclick')"
              v-on="handlers({ serial, item, day })"
              v-for="day in days"
              :key="`col_${row}_${day}`"
              class="table-day-cell"
              :class="calcCellClass(serial, item, day)"
              :width="cellSize"
              :height="cellSize"
              :x="((day - 1) * (cellSize + 7)) + 110"
              :y="(row * (cellSize + 7)) + 1">
            </rect>
          </g>
        </g>
      </svg>
      <v-tooltip
        top
        :position-x="tooltip.x"
        :position-y="tooltip.y"
        :value="tooltip.show"
        v-if="tooltip.show">
        <span>운행이력: {{ tooltip.count }}건</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeatmapTable',
  props: {
    date: {
      type: String,
      required: true,
    },
    items: {
      required: false,
    },
    select: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  data: () => ({
    cellSize: 16,
    holidayOffset: 0,
    days: 30,
    tooltip: {
      x: 0,
      y: 0,
      count: 0,
      show: false,
    },
  }),
  computed: {
    realSize() {
      return {
        width: ((this.cellSize + 7) * 31) + 110,
        height: Object.keys(this.items).length * (this.cellSize + 7),
      };
    },
    selectItem: {
      get() {
        return this.select;
      },
      set(value) {
        this.$emit('update:select', value);
      },
    },
  },
  watch: {
    date: {
      immediate: true,
      handler(value) {
        const date = new Date(value);
        this.holidayOffset = date.getDay() - 1;
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);
        this.days = date.getDate();
      },
    },
  },
  mounted() {
  },
  methods: {
    onResize() {
      this.$nextTick(() => {
        const { container } = this.$refs;
        const width = (container != null) ? container.clientWidth : 800;
        this.cellSize = ((width - 150) / 31) - 7;
      });
    },
    onShowInfo(count, event) {
      switch (event.type) {
        case 'mouseover':
          this.tooltip.show = true;
          this.tooltip.count = count;
          this.tooltip.x = event.clientX;
          this.tooltip.y = event.clientY;
          break;
        case 'mouseleave':
          this.tooltip.show = false;
          break;
        default: break;
      }
    },
    getDate(day) {
      const strDay = (day > 9) ? `${day}` : `0${day}`;
      return `${this.date.slice(0, 8)}${strDay}`;
    },
    calcItemCount(item, date) {
      return (item[date] != null) ? item[date] : 0;
    },
    calcCellClass(serial, item, day) {
      const date = this.getDate(day);
      const count = this.calcItemCount(item, date);
      return [
        `table-day-cell-${(count > 5) ? 5 : count}`,
        (serial === this.selectItem.serial && date === this.selectItem.date)
          ? 'table-day-cell-select' : '',
      ];
    },
    calcLabelClass(serial) {
      return [
        (serial === this.selectItem.serial) ? 'table-label-select' : '',
      ];
    },
    calcDayLabelClass(day) {
      return {
        'table-day-label-select': (day === parseInt(this.selectItem.date.slice(8, 10), 10)),
        'table-day-label-holiday': (((day + this.holidayOffset) % 7) === 0),
      };
    },
    handlers({ serial, item, day }) {
      const component = this;
      const date = this.getDate(day);
      const count = this.calcItemCount(item, date);

      return {
        click() {
          component.selectItem = {
            serial,
            date,
            name: item.name,
            count,
          };
        },
        mouseover(event) {
          component.onShowInfo(count, event);
        },
        mouseleave(event) {
          component.onShowInfo(count, event);
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-label {
  rect {
    fill: transparent;
  }

  text {
    fill: #4E4E4E;
    font-size: .9rem;
    text-anchor: start;
  }

  &-select {
    rect {
      fill: #ffcccc;
    }

    text {
      font-weight: bold;
      color: var(--color-text-primary);
    }
  }
}

.table-day-label {
  text {
    fill: var(--color-text-primary);
    font-size: .9rem;
    font-weight: bold;
    text-anchor: middle;
  }

  rect {
    fill: transparent;
  }

  &-select {
    rect {
      fill: #ffcccc;
    }
  }

  &-holiday {
    fill: #ff5252;
  }
}

.table-day-cell {
  fill: #c1c1c1;
  shape-rendering: geometricPrecision;
  outline: 1px solid #888888;

  &-select {
    outline: 2px solid #ff5252;
  }

  &-1 {
    fill: hsl(207, 23%, 64%);
  }

  &-2 {
    fill: hsl(206, 28%, 58%);
  }

  &-3 {
    fill: hsl(206, 43%, 53%);
  }

  &-4 {
    fill: hsl(206, 66%, 44%);
  }

  &-5 {
    fill: hsl(206, 90%, 39%);
  }
}
</style>
