var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"mobile-breakpoint":null,"show-select":"","dense":"","item-key":"serial","headers":_vm.headers,"items":_vm.filteredItems,"footer-props":{
      itemsPerPageOptions: [50, 100, -1],
    },"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('device-table2-header',_vm._g(_vm._b({},'device-table2-header',props,false),on))]}},{key:"item.show",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onSetVisibility(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s((item.show) ? 'mdi-eye' : 'mdi-eye-off')+" ")])],1)]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.name,"large":"","persistent":"","save-text":"수정","cancel-text":"취소"},on:{"update:returnValue":function($event){return _vm.$set(item, "name", $event)},"update:return-value":function($event){return _vm.$set(item, "name", $event)},"open":function($event){_vm.editor.name=item.name},"save":function($event){return _vm.onUpdate(Object.assign({}, item, {name: _vm.editor.name}))}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"휴대폰 번호","single-line":""},model:{value:(_vm.editor.name),callback:function ($$v) {_vm.$set(_vm.editor, "name", $$v)},expression:"editor.name"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.temp_flag",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDeviceAllowState(item))+" ")]}}]),model:{value:(_vm.selectedItems2),callback:function ($$v) {_vm.selectedItems2=$$v},expression:"selectedItems2"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }