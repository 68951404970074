<template>
  <layout-card
    :width="300"
    :height="200"
    title="운행 데이터">
    <template v-slot:body>
      <table class="pl-2 pr-2 card-info-list">
        <tr
          v-for="(item, idx) in info.items"
          :key="`item_${idx}`">
          <td class="card-info-title">
            {{ item.title }}
          </td>
          <td class="card-info-value">
            {{ self[item.key] }}
          </td>
        </tr>
      </table>
    </template>
  </layout-card>
</template>

<script>
import LayoutCard from '@/components/card/LayoutCard.vue';

export default {
  name: 'CardDriveInfo',
  components: {
    LayoutCard,
  },
  props: {
    serial: {
      type: String,
      default: '-',
    },
    drivingLog: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    info: {
      items: [
        { title: '운행시간/일', key: 'timeD1' },
        { title: '운행거리/일', key: 'distanceD1' },
        { title: '운행시간/월', key: 'timeMonth' },
        { title: '운행시간/월', key: 'distanceMonth' },
      ],
    },
    stats: {
      distance: {
        month: 0,
        day: 0,
      },
      time: {
        month: 0,
        day: 0,
      },
    },
  }),
  computed: {
    self() {
      return this;
    },
    distanceD1() {
      return this.toDistString(this.stats.distance.day);
    },
    distanceMonth() {
      return this.toDistString(this.stats.distance.month);
    },
    timeD1() {
      return this.toTimeString(this.stats.time.day);
    },
    timeMonth() {
      return this.toTimeString(this.stats.time.month);
    },
  },
  watch: {
    'drivingLog.items': {
      immediate: true,
      handler() {
        this.calcDrivingStats();
      },
    },
  },
  methods: {
    toDistString(distance) {
      const unit = (distance >= 1000) ? 'km' : 'm';
      const value = (distance >= 1000) ? (Math.floor(distance / 100) / 10) : distance;
      return `${value}${unit}`;
    },
    toTimeString(time) {
      const minUnit = Math.floor(time / 1000 / 60);
      const hour = Math.floor(minUnit / 60);
      const min = minUnit - (hour * 60);
      return `${hour} 시간 ${min} 분`;
    },
    calcDrivingStats() {
      const lastDay = new Date();
      lastDay.setDate(lastDay.getDate() + 1);
      lastDay.setHours(0, 0, 0);

      const day = new Date(lastDay);
      day.setUTCDate(day.getUTCDate() - 1);

      const month = new Date(day);
      month.setUTCMonth(month.getUTCMonth() - 1);

      const stats = {
        distance: {
          month: 0,
          day: 0,
        },
        time: {
          month: 0,
          day: 0,
        },
      };

      this.drivingLog.items.forEach((item) => {
        const start = new Date(item.start);
        const end = (item.end != null) ? new Date(item.end) : null;

        if (start > month && end != null) {
          stats.distance.month += item.distance;
          stats.time.month += (end - start);
        }
        if (start > day && end != null) {
          stats.distance.day += item.distance;
          stats.time.day += (end - start);
        }
      });

      this.stats = stats;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-info-list {
  border-collapse: separate;
  border-spacing: 0 8px;

  .card-info-title {
    color: #989898;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    width: 96px;
  }

  .card-info-value {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
  }
}
</style>
